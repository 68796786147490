@charset "UTF-8";
/* ----------------------------------------------------------------
base
-----------------------------------------------------------------*/
/**
* init
**/
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  // -webkit-font-smoothing: antialiased;
  // font-smoothing: antialiased;
  text-rendering: auto;
  font-size: 100%;
  line-height: 1.0;
  font-family: $fontFamily;
  color: $clrBlck;
  width: 100%;
  &.is-recruit,
  &.page-template-page-recruit-children,
  &.page-template-page-recruit-grandchildren {
    color: $rctBlck;
  }
}
a,
a:link,
a:visited,
a:hover,
a:active {
	text-decoration: none;
}
p,
li,
dt,
dd {
  font-weight: normal;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
	font-weight: bold;
  margin: 0;
  padding: 0;
}
ul,
ol,
dl {
  margin: 0;
  padding: 0;
}
ul > li {
	list-style: none;
}
ol > li {
  list-style-position: inside;
}
strong,b {
  font-weight: bold;
  // font-size: 120%;
}
img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

input[type=text],
textarea,
button,
select {
	vertical-align: middle; //	cursor: pointer;
	appearance: none;
}

input[type="submit"]:disabled {
	opacity: 0.65;
	cursor: default;
}

/**
* icon font
**/
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'JTS' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-window:before {
  content: "\e90b";
}
.icon-pdf:before {
  content: "\e90a";
}
.icon-angle_down:before {
  content: "\e900";
}
.icon-angle_left:before {
  content: "\e901";
}
.icon-angle_right:before {
  content: "\e902";
}
.icon-angle_top:before {
  content: "\e903";
}
.icon-layer:before {
  content: "\e904";
}
.icon-link_down:before {
  content: "\e905";
}
.icon-link_left:before {
  content: "\e906";
}
.icon-link_right:before {
  content: "\e907";
}
.icon-link_top:before {
  content: "\e908";
}
.icon-map:before {
  content: "\e909";
}

/**
* slick
**/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
