@charset "UTF-8";
/* ----------------------------------------------------------------
breadcrumb（パンくずリスト）のスタイル
-----------------------------------------------------------------*/
.p-breadcrumb {
  width: 100%;
  margin-top: 25px;
}
.p-breadcrumb__list {
  font-size: 0;
  @include contPad;
  max-width: 1440px;
  display: flex;
  align-items: center;
}
.p-breadcrumb__item {
  color: #000;
  @include fontsize(12);
  &::before {
    content: "/";
    color: #ddd;
    margin: 0 1em;
  }
}
.p-breadcrumb__item:first-child::before {
  content: none;
}
a.p-breadcrumb__label {
  color: #000;
  text-decoration: underline;
}
a.p-breadcrumb__label:hover {
  text-decoration: none;
}

@include mq-sp {
  .p-breadcrumb {
    margin-top: 10px;
  }
  .p-breadcrumb__list {
    font-size: 0;
    width: 100%;
    display: block;
    padding: 0 20px;
  }
  .p-breadcrumb__item {
    font-size: 10px;
    display: inline-block;
    margin-top: 10px;
    &::before {
      margin: 0 0.4em;
    }
  }
  a.p-breadcrumb__label:hover {
    text-decoration: underline;
  }
}