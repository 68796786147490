@charset "UTF-8";
/*----------------------------------------------------------------
ボタンのコンポーネント
-----------------------------------------------------------------*/
.c-button-icon {
  display: inline-block;
}
.c-button-icon.icon-link_right,
.c-button-icon.icon-link_left {
  font-size: 17px;
  margin-right: 6px;
}
.c-button-icon.icon-angle_right {
  font-size: 12px;
  margin-right: 6px;
}
.c-button-icon.icon-window {
  color: $blue;
  font-size: 16px;
  margin-right: 6px;
  vertical-align: middle;
}
.c-button-icon.icon-pdf {
	display: inline-block;
	color: #d30000;
	font-size: 14px;
	margin-right: 10px;
}

@include mq-sp {
  .c-button-icon.icon-link_right,
  .c-button-icon.icon-link_left {
    font-size: 14px;
    margin-right: 8px;
  }
  .c-button-icon.icon-pdf {
    font-size: 13px;
  }
}

.c-mark-plus {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  &::before,&::after {
    content: "";
    display: block;
    background: #FFF;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  &::before {
    width: 10px;
    height: 2px;
  }
  &::after {
    width: 2px;
    height: 10px;
  }
}

.c-button-arrow {
  color: $blue;
	@include fontsize(16);
	font-weight: bold;
	line-height: 1;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	width: 280px;
  background-color: #fff;
	border: 1px solid $blue;
	border-radius: 5px;
	padding: 20px 0;
	cursor: pointer;
	@include hoverOpacity;

	@include mq-sp {
		@include fontsize(14);
		display: block;
		text-align: center;
		width: 100%;
		padding: 19px 0;
		position: relative;
	}

	&:before {
    font-family: 'JTS' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e907";
		font-size: 17px;

		@include mq-sp {
			font-size: 15px;
			height: 15px;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 26px;
			margin: auto;
		}
	}

	&.is-inverted-color {
		color: #fff;
		background-color: $blue;
	}
}