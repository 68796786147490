@charset "UTF-8";
/* ----------------------------------------------------------------
globalnaviのスタイル
-----------------------------------------------------------------*/
.p-header {
  @include contPad(0,0);
  max-width: 1600px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-header__logo {
  display: block;
}
.p-header__navi {
  display: block;
}
/*---------- ロゴ ----------*/
.p-header-logo {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.p-header-logo__symbol {
  width: 87px;
  img {
    width: 100%;
    height: auto;
  }
  @include mq-lt {
    width: 74px;
  }
}
.p-header-logo__type {
  width:240px;
  padding-left:20px;
}
.p-header-logo__text {
  padding-left: 20px;
  @include mq-lt {
    padding-left: 10px;
  }
}
.p-header-logo__text-header {
  color: #000;
  @include fontsize(14);
  letter-spacing: -0.05em;
  font-weight: bold;
  display: block;
  @include mq-lt {
    @include fontsize(12);
  }
}
.p-header-logo__text-50s {
  width: auto;
  height: 15px;
  display: block;
  margin-top: 5px;
}
@include mq-sp {
  .p-header-logo {
    margin-left: 10px;
    padding-right: 10px;
  }
  .p-header-logo__symbol {
    width: 50px;
  }
  .p-header-logo__type {
    width: calc(164 / 375 * 100vw);
    padding-left: 10px;
  }
  .p-header-logo__text {
    padding-left: 10px;
  }
  .p-header-logo__text-header {
    font-size: 9px;
    line-height: 10px;
  }
  .p-header-logo__text-50s {
    height: 10px;
    margin-top: 3px;
  }
}
/*---------- グローバルナビ ----------*/
.p-globalnavi {
  display: flex;
}
.p-globalnavi__menu {
  display: flex;
  flex: 1 0 auto;
}
.p-globalnavi__item {
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 14px;
    background: $bdGray;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
.p-globalnavi__item:first-child {
  &::before {
    content: none;
  }
}
.p-globalnavi__label {
  color: #000;
  @include fontsize(14);
  display: flex;
  align-items: center;
  height: 95px;
  // padding: 0 30px;
  padding: 0 25px;
  @include hoverColor;
  @include mq-lt {
    @include fontsize(13);
    letter-spacing: -0.05em;
    padding: 0 20px;
  }
}
.p-globalnavi__recruit {
  // width: 160px;
  width: 150px;
  height: 100%;
  @include mq-lt {
    width: 120px;
  }
}
.p-globalnavi__recruit-label {
  color: #FFF;
  @include fontsize(14);
  background: $blue;
  display: flex;
  align-items: center;
  height: 95px;
  justify-content: center;
  @include hoverOpacity;
  @include mq-lt {
    @include fontsize(13);
    letter-spacing: -0.05em;
  }
}
.p-globalnavi__recruit-pict {
  width: 30px;
  height: auto;
  margin-right: 8px;
  vertical-align: middle;
  @include mq-lt {
    width: 25px;
  }
}
.p-navi-button {
  display: none;
}

@include mq-sp {
  .p-globalnavi {
    height: 60px;
    padding: 10px 0;
    display: flex;
  }
  .p-globalnavi__menu {
    display: none;
  }
  .p-globalnavi__recruit {
    width: 55px;
  }
  .p-globalnavi__recruit-label {
    font-size: 10px;
    font-weight: bold;
    display: block;
    height: 100%;
    padding-top: 5px;
    text-align: center;
  }
  .p-globalnavi__recruit-pict {
    width: 21px;
    height: auto;
    margin-right: 0;
  }
  .p-globalnavi__recruit-text {
    display: block;
    text-align: center;
    margin-top: 5px;
  }
  .p-navi-button {
    display: block;
    width: 55px;
    height: 100%;
    cursor: pointer;
  }
  .p-navi-button__inner {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .p-navi-button__line {
    display: inline-block;
    width: 18px;
    height: 2px;
    background: $blue;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  .p-navi-button__line:nth-child(1) {
    top: 11px;
  }
  .p-navi-button__line:nth-child(2) {
    top: 0;
    bottom: 0;
  }
  .p-navi-button__line:nth-child(3) {
    bottom: 11px;
  }
  .p-navi-button.is-close .p-navi-button__line:nth-child(1) {
    top: 0;
    bottom: 0;
    transform: rotate(45deg);
  }
  .p-navi-button.is-close .p-navi-button__line:nth-child(2) {
    display: none;
  }
  .p-navi-button.is-close .p-navi-button__line:nth-child(3) {
    top: 0;
    bottom: 0;
    transform: rotate(-45deg);
  }
}
/*---------- グローバルナビ（SP） ----------*/
.p-navi-sp {
  display: none;
  @include mq-sp {
    width: 100%;
    background: $blue;
    padding: 20px 20px 0;
    position: absolute;
    top: 60px;
  }
}
.p-navi-sp__inner {
  width: 100%;
}
.p-navi-sp__menu {
  border: 1px solid #FFF;

}
.p-navi-sp__item {
  display: block;
  height: 55px;
  border-top: 1px solid #FFF;
  &:first-child {
    border-top: none;
  }
}
.p-navi-sp__label {
  color: #FFF;
  font-size: 14px;
  line-height: 55px;
  display: block;
  padding-left: 20px;
  position: relative;
  i {
    color: #FFF;
    font-size: 15px;
    line-height: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
  }
}
.p-navi-50th {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 290px;
  height: 60px;
  margin: 30px auto 10px;
  background-color: #EBFBFF;
  border: 1px solid #00C6FF;
  > img {
    width: 244px;
    height: auto;
  }
}
.p-navi-sp__close {
  color: #FFF;
  font-size: 12px;
  line-height: 55px;
  height: 55px;
  display: block;
  text-align: center;
}