@charset "UTF-8";
/* ----------------------------------------------------------------
mainのスタイル
-----------------------------------------------------------------*/
.l-contents {
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 1;
}
.l-page-header {
  width: 100%;
  background-color: #fff;
}
.l-container {
  @include contPad;
  max-width: 1440px;
  padding-bottom: 100px;
  @include clearfix;
  @include mq-sp {
    width: auto;
    padding-bottom: 50px;
  }
}
.l-main {
  width: 100%;
  float: left;
  margin-top: 80px;
  margin-right: -340px;
  padding-right: 340px;
  @include mq-sp {
    float: none;
    margin-top: 30px;
    margin-right: 0;
    padding-right: 0;
  }
}
.l-main.size-l {
  float: none;
  margin-top: 100px;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 100px;
  &.pb-0 {
    padding-bottom: 0;
  }
  @include mq-sp {
    margin-top: 30px;
    padding-bottom: 50px;
  }
}
.l-side {
  float: right;
  width: 260px;
  margin-top: 40px;
  position: relative;
  @include mq-sp {
    float: none;
    width: auto;
    margin-top: 50px;
  }
}
.l-main.is-recruit {
  float: none;
  margin-right: 0;
  padding: 100px 0 80px;
  // padding-right: 0;
  // padding-bottom: 80px;
  position: relative;
  @include mq-sp {
    padding: 90px 0 40px;
    // margin: 40px 0;
    // padding-bottom: 40px;
  }
}
.page-template-page-recruit-children .l-main {
  float: none;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 0;
  padding: 70px 0 60px;
  position: relative;
  @include mq-sp {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 35px 0 30px;
  }
}
.page-template-page-recruit-grandchildren .l-main {
  float: none;
  margin-top: 110px;
  margin-bottom: 80px;
  margin-right: 0;
  padding: 0;
  position: relative;
  @include mq-sp {
    margin-top: 70px;
    margin-bottom: 40px;
    padding: 0;
  }
}
