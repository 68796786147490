@charset "UTF-8";
/*----------------------------------------------------------------
見出しのコンポーネント
-----------------------------------------------------------------*/
.c-headline-icon {
  color: #000;
  @include fontsize(28);
  font-weight: bold;
  line-height: 1.2;
  text-indent: -34px;
  padding: 0 34px 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid $bdGray;
  @include mq-sp {
    font-size: 16px;
    text-indent: -15px;
    padding: 0 15px 10px;
    margin-bottom: 20px;
  }
}
.c-headline-icon > i.mark-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 5px solid $blue;
  border-radius: 50%;
  margin-right: 14px;
  vertical-align: baseline;
  @include mq-sp {
    width: 10px;
    height: 10px;
    border: 3px solid $blue;
    margin-right: 7px;
  }
}
.c-headline-icon > i.icon-map {
  color: $blue;
  font-size: 28px;
  margin-right: 15px;
  position: relative;
  top: 2px;
  @include mq-sp {
    font-size: 15px;
    margin-right: 6px;
    top: 1;
  }
}
.c-headline-subtitle {
  color: $blue;
  @include fontsize(18);
  font-weight: normal;
  padding-left: 60px;
  margin-left: 20px;
  position: relative;
  vertical-align: middle;
  &::before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 1px;
    background: $bdGray;
    position: absolute;
    top: 9px;
    left: 0;
  }
  @include mq-sp {
    font-size: 13px;
    padding-left: 0;
    margin-left: 0;
    margin-top: 15px;
    display: block;
    &::before {
      content: none;
    }
  }
}
.c-headline-bar {
  color: #000;
  @include fontsize(20);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 40px;
  padding-left: 40px;
  position: relative;
  &::before {
    content: "";
    display: block;
    width: 20px;
    height: 3px;
    position: absolute;
    top: 13px;
    left: 0;
    background: $blue;
  }
  @include mq-sp {
    font-size: 14px;
    margin-bottom: 20px;
    padding-left: 15px;
    &::before {
      width: 10px;
      top: 8px;
    }
  }
}
.c-headline-bar.color-green {
  &::before {
    background: $green;
  }
}
.c-headline-simple {
  color: #000;
  @include fontsize(20);
  font-weight: normal;
  line-height: 1.3;
  margin-bottom: 10px;
  @include mq-sp {
    font-size: 15px;
		font-weight: bold;
  }
}
.c-headline-simple.size-s--sp {
  @include mq-sp {
    font-size: 12px;
  }
}

.c-headline-number {
  width: 100%;
  margin-bottom: 40px;
  @include clearfix;
}
.c-headline-number__num {
  color: $blue;
	@include fontsize(24);
	font-weight: bold;
	line-height: 30px;
	display: inline-block;
	// padding: 0 2px 7px;
	position: relative;
	float: left;
	// &::after {
	// 	content: "";
	// 	display: block;
	// 	width: 100%;
	// 	height: 3px;
	// 	background: $blue;
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 0;
	// }
}
.c-headline-number__text {
  color: #000;
  @include fontsize(20);
  font-weight: bold;
  line-height: 1.5;
  float: right;
  padding-right: 40px;
  margin-right: -40px;
  width: 100%;
}
@include mq-sp {
  .c-headline-number {
    margin-bottom: 20px;
  }
  .c-headline-number__num {
    font-size: 18px;
    line-height: 21px;
		padding: 0;
    // font-size: 18px;
    // padding: 0 4px 6px;
    // top: -2px;
    // &::after {
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 3px;
    //   background: #00c8ff;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    // }
  }
  .c-headline-number__text {
    font-size: 14px;
    padding-right: 20px;
    margin-right: -20px;
    // padding-right: 30px;
    // margin-right: -30px;
  }
}