@charset "UTF-8";
/* ----------------------------------------------------------------
変数の設定項目
-----------------------------------------------------------------*/

/* font-familyの設定
-----------------------------------------------------------------*/
/**
 * 適用範囲
 * テキスト全般
 * 見出し
 */

//テキスト全般
// $fontFamily: "Noto Sans Japanese", YuGothic, '游ゴシック', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Arial, Verdana, sans-serif;
$fontFamily: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Arial, Verdana, sans-serif;
$fontYugo: YuGothic, '游ゴシック', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Arial, Verdana, sans-serif;

//明朝体
$fontFamilyMin: "YuMincho", "游明朝", 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', serif;

//見出しに適用
// $fontFamilyheadline: "Noto Sans CJK JP", YuGothic, '游ゴシック', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", Helvetica, Arial, Verdana, sans-serif;

//Ubuntuを含むテキスト
$fontUbuntu: 'Ubuntu', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;

//Oswald
$fontOswald: 'Oswald', sans-serif;


/* パスの設定
-----------------------------------------------------------------*/
$src: "/";


/* font-size・line-heightの設定
-----------------------------------------------------------------*/
/**
 * 適用範囲
 * ・テキスト全般
 * ・見出し
 */

 // font-sizeの設定[rem]
$fsBase: 1.6; //base
$fsBaseSp: 1.4; //sp base
$fsL1: 3.2; //見出しLv1
$fsL2: 2.8; //見出しLv2
$fsL3: 2.4; //見出しLv3
$fsL5: 1.6; //見出しLv4
$fsL4: 2.0; //見出しLv5
$fsL6: 1.4; //見出しLv6
$fsSmall: 1.4; //サブ見出しサイズ

// line-heightの設定
$lhBase: 1.8; //base
$lhBaseSp: 1.8; //sp base


/* colorの設定
-----------------------------------------------------------------*/
/**
 * 適用範囲
 * ・リンクのスタイル
 * ・utility
 */

/*---------- 一般的なカラー ----------*/
$clrBlck: #000;  //サイトの黒を定義
$rctBlck: #1C1C1C;  //採用サイトの黒を定義
$clrWht: #fff;  //サイトの白を定義
$clrGry: #505050;  //サイトの灰色を定義

/*---------- プロジェクトカラー ----------*/
$clrBase: #fff; //ベースカラー
$clrMain: #b21920; //メインカラー
$clrAccent: #555555; //アクセントカラー
$clrProject01: #ed1c24; //その他
$clrProject02: #ff828c; //その他
$clrProject03: #fbd146; //その他
$clrProject04: #21dfc2; //その他
$clrProject05: #57affa; //その他
$clrPrimary: #4285F4; //選択
$clrInfo: #3498DB; //情報
$clrSuccess: #0F9D58; //成功
$clrWarning: #FF5722; //注意
$clrDanger: #F44336; //危険
$blue: #00c8ff; //ブルー
$rctBlue: #00c9ff; //リクルート用ブルー
$bgBlue: #f3f9fd; //背景用ブルー
$hoverBlue: #03a8f4; //hover用ブルー
$ftBlue: #3498db; //フォント用ブルー
$green: #00e000; //GREEN
$ftGray: #555; //フォント用グレー
$bgGray: #f8f8f8; //背景用グレー
$bdGray: #e5e5e5; //ボーダー用グレー

/*---------- グラデーション ----------*/
$grad: linear-gradient(to right, #00c9ff 0%,#00ffff 50%,#00e000 100%);

/* ブレイクポイント
-----------------------------------------------------------------*/
/**
 * 適用範囲
 * ・サイト全体
 */

$queryPc: "screen and (min-width: 768px)";//PC
$queryTab: "only screen and (max-width: 1279px)";//TAB
$querySp: "not screen and (min-width: 768px)";//SP
