@charset "UTF-8";
/* ----------------------------------------------------------------
Noto Sans CJK
-----------------------------------------------------------------*/
/**
* Noto Sans CJK JP: https://www.google.com/get/noto/#sans-jpan
* Noto Sans JP: https://fonts.google.com/earlyaccess#Noto+Sans+JP
*/
// @font-face {
// 	font-family: 'Noto Sans Japanese';
// 	font-style: normal;
// 	font-weight: 100;
// 	src: local("Noto Sans CJK JP"),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format('opentype');
// }
// @font-face {
// 	font-family: 'Noto Sans Japanese';
// 	font-style: normal;
// 	font-weight: 300;
// 	src: local("Noto Sans CJK JP"),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format('opentype');
// }
// @font-face {
// 	font-family: 'Noto Sans Japanese';
// 	font-style: normal;
// 	font-weight: 400;
// 	src: local("Noto Sans CJK JP"),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format('opentype');
// }
// @font-face {
// 	font-family: 'Noto Sans Japanese';
// 	font-style: normal;
// 	font-weight: 500;
// 	src: local("Noto Sans CJK JP"),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format('opentype');
// }
// @font-face {
// 	font-family: 'Noto Sans Japanese';
// 	font-style: normal;
// 	font-weight: 700;
// 	src: local("Noto Sans CJK JP"),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format('opentype');
// }
// @font-face {
// 	font-family: 'Noto Sans Japanese';
// 	font-style: normal;
// 	font-weight: 900;
// 	src: local("Noto Sans CJK JP"),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format('opentype');
// }

/* ----------------------------------------------------------------
Ubuntu
-----------------------------------------------------------------*/
/**
* Ubuntu: https://fonts.google.com/specimen/Ubuntu
*/
// Ubuntu(400, 500) / Oswald(700)
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Ubuntu:wght@400;500&display=swap');

/* ----------------------------------------------------------------
icon
-----------------------------------------------------------------*/
@font-face {
  font-family: 'JTS';
  src:  url('../fonts/JTS.eot?ix5t0f');
  src:  url('../fonts/JTS.eot?ix5t0f#iefix') format('embedded-opentype'),
    url('../fonts/JTS.ttf?ix5t0f') format('truetype'),
    url('../fonts/JTS.woff?ix5t0f') format('woff'),
    url('../fonts/JTS.svg?ix5t0f#JTS') format('svg');
  font-weight: normal;
  font-style: normal;
}
