@charset "UTF-8";
/*----------------------------------------------------------------
リストのコンポーネント
-----------------------------------------------------------------*/

// .c-list-dot {
//   > li {}
// }
// .c-list-bar {
//   > li {}
// }

.c-list-alphabet {
	list-style-type: none;
  counter-reset: alphabet;

	> li {
    font-size: inherit;
		line-height: 1.5;
		padding-left: 1.5em;
		position: relative;

		&::before {
			counter-increment: alphabet;
			content: counter(alphabet, lower-alpha) "）";
			width: 1.5em;
			position: absolute;
			top: 0;
			left: 0;
		}

		+ li {
			margin-top: 40px;
		}

    > .c-list-order {
      margin-top: 20px;
      > li {
        + li {
          margin-top: 20px;
        }
      }
    }

	}

  @include mq-sp {
    > li {
      + li {
        margin-top: 20px;
      }

      > .c-list-order {
        margin-top: 10px;
        > li {
          + li {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.c-list-order {
	list-style-type: none;
	counter-reset: number;

	> li {
    font-size: inherit;
		line-height: 1.5;
		padding-left: 1.5em;
		position: relative;

		&::before {
			counter-increment: number;
			content: counter(number) "）";
			width: 1.5em;
			position: absolute;
			top: 0;
			left: 0;
		}

		+ li {
			margin-top: 40px;
		}

    > .c-list-order-child {
      margin-top: 20px;
    }

	}

  @include mq-sp {
    > li {
      + li {
        margin-top: 20px;
      }

      > .c-list-order-child {
        margin-top: 20px;
      }
    }
  }
}

.c-list-order-child {
  list-style-type: none;
  counter-reset: child-number;

  > li {
    font-size: inherit;
    line-height: 1.5;
    padding-left: 1.25em;
    position: relative;

    &::before {
      counter-increment: child-number;
      content: counter(child-number);
      font-size: .8em;
      line-height: 1.25;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.25em;
      height: 1.25em;
      border-radius: 50%;
      border: 1px solid currentColor;
      position: absolute;
      top: .25em;
      left: 0;
    }

    + li {
			margin-top: 20px;
		}

  }

  @include mq-sp {
    > li {
      + li {
        margin-top: 10px;
      }
    }
  }
}