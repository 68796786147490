@charset "UTF-8";
/* ----------------------------------------------------------------
	pageのスタイル
-----------------------------------------------------------------*/
/**
* Common
**/
/*---------- ページタイトル ----------*/
.p-page-title {
	height: 300px;
	position: relative;
}
.p-page-title__image {
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
}
.p-page-title__text {
	color: #FFF;
	@include fontsize(48);
	font-weight: bold;
	display: block;
	width: 100%;
	padding: 0 20px;
	text-align: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

@include mq-sp {
	.p-page-title {
		height: 100px;
	}
	.p-page-title__text {
		font-size: 24px;
		line-height: 1.2;
	}
}

/*---------- layout ----------*/
.p-main-wrapper {
	width: 100%;
	margin: 0 auto;
}
.p-main-wrapper.size-m {
	max-width: calc(1000px + 80px);
	padding: 0 40px;
	@include mq-sp {
		max-width: initial;
		padding: 0;
	}
}
.p-main-wrapper.size-s {
	max-width: calc(940px + 80px);
	padding: 0 40px;
	@include mq-sp {
		max-width: initial;
		padding: 0;
	}
}
.p-page-section {
	margin-top: 100px;
	@include clearfix;
	@include mq-sp {
		margin-top: 40px;
	}
}
.p-page-section.margin-s {
	margin-top: 40px;
	@include clearfix;
	@include mq-sp {
		margin-top: 20px;
	}
}
.p-page-paragraph {
	margin-bottom: 20px;
	@include mq-sp {
		margin-bottom: 10px;
	}
}

/*---------- float ----------*/
.p-float-left {
	display: inline-block;
	float: left;
	margin-right: 40px;
	margin-bottom: 40px;
}
.p-float-right {
	display: inline-block;
	float: right;
	margin-left: 40px;
	margin-bottom: 40px;
}
@include mq-sp {
	.p-float-none--sp {
		float: none;
		display: block;
		text-align: center;
		margin-left: 0;
		margin-right: 0;
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

/*---------- column ----------*/
.p-page-column {
	display: flex;
	flex-wrap: wrap;
}
.p-page-column.column-2 > .p-page-column__box {
	width: 50%;
}
.p-page-column.column-3 > .p-page-column__box {
	width: 33.3333%;
}
.p-page-column.column-4 > .p-page-column__box {
	width: 25%;
}
.p-page-column.padding-10 {
	width: calc(100% + 10px);
	margin-left: -10px;
	margin-bottom: -10px;
}
.p-page-column.padding-20 {
	width: calc(100% + 20px);
	margin-left: -20px;
	margin-bottom: -20px;
}
.p-page-column.padding-40 {
	width: calc(100% + 40px);
	margin-left: -40px;
	margin-bottom: -40px;
}
.p-page-column.padding-60 {
	width: calc(100% + 60px);
	margin-left: -60px;
	margin-bottom: -60px;
}
.p-page-column.padding-10 > .p-page-column__box {
	padding-left: 10px;
	padding-bottom: 10px;
}
.p-page-column.padding-20 > .p-page-column__box {
	padding-left: 20px;
	padding-bottom: 20px;
}
.p-page-column.padding-40 > .p-page-column__box {
	padding-left: 40px;
	padding-bottom: 40px;
}
.p-page-column.padding-60 > .p-page-column__box {
	padding-left: 60px;
	padding-bottom: 60px;
}
@include mq-sp {
	.p-page-column.column-1--sp {
		width: 100%;
		margin-left: 0;
		margin-bottom: 0;
		display: block;
	}
	.p-page-column.column-1--sp > .p-page-column__box {
		width: 100%;
		padding-left: 0;
		padding-bottom: 0;
	}
	.p-page-column.padding-10.column-1--sp > .p-page-column__box {
		padding-top: 5px;
	}
	.p-page-column.padding-20.column-1--sp > .p-page-column__box {
		padding-top: 10px;
	}
	.p-page-column.padding-40.column-1--sp > .p-page-column__box {
		padding-top: 20px;
	}
	.p-page-column.padding-60.column-1--sp > .p-page-column__box {
		padding-top: 30px;
	}
	.p-page-column.column-1--sp > .p-page-column__box:first-child {
		padding-top: 0;
	}
}

/*---------- テキスト ----------*/
.p-text-regular {
	color: $ftGray;
	@include fontsize(14);
	line-height: 1.8;
	@include mq-sp {
		font-size: 13px;
	}
}
.p-text-small {
	color: $ftGray;
	@include fontsize(12);
	line-height: 18px;
	@include mq-sp {
		font-size: 11px;
	}
}
//字下げ
.p-indent-1em {
	text-indent: -1em;
	padding-left: 1em;
}

/*---------- リスト ----------*/
ul.p-list-indent > li,
ol.p-list-indent > li {
	padding-left: 1em;
  text-indent: -1em;
}

/*---------- テーブル ----------*/
.p-table {
	width: 100%;
	border-top: 1px solid $bdGray;
}
.p-table__row,
.p-table__rowheader {
	display: flex;
}
.p-table__th {
	color: $ftGray;
	@include fontsize(14);
	line-height: 22px;
	width: 230px;
	text-align: center;
	padding: 20px 0;
	background: $bgGray;
	border-bottom: 1px solid $bdGray;
}
.p-table__th.position-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.p-table__rowheader .p-table__th {
	color: #FFF;
	background: #555;
	border-bottom: none;
}
.p-table__td {
	color: $ftGray;
	@include fontsize(14);
	line-height: 22px;
	padding: 20px 20px;
	flex: 1;
	border-bottom: 1px solid $bdGray;
	a {
		color: $ftGray;
		text-decoration: underline;
	}
	a:hover {
		text-decoration: none;
	}
}
.p-table__td.position-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.p-table__rowheader .p-table__td {
	color: #FFF;
	background: #8e8e8e;
	text-align: center;
	border-left: 1px solid $bdGray;
}
@include mq-sp {
	.p-table {
		width: 100%;
		border: 1px solid $bdGray;
	}
	.p-table__row {
		display: block;
	}
	.p-table__rowheader {
		display: none;
	}
	.p-table__th {
		color: #000;
		font-size: 13px;
		line-height: 1.8;
		width: 100%;
		text-align: left;
		padding: 8px 10px;
		border-top: 1px solid $bdGray;
		border-bottom: none;
	}
	.p-table__row:first-child > .p-table__th {
		border-top: none;
	}
	.p-table__td {
		font-size: 13px;
		line-height: 1.8;
		padding: 8px 10px;
		width: 100%;
		border-bottom: none;
		border-top: 1px solid $bdGray;
		a:hover {
			text-decoration: underline;
		}
	}
}

.p-table.style-plain {
	border-top: none;
}
.p-table.style-plain .p-table__th {
	background: none;
	border-bottom: 2px solid #CCC;
}
.p-table.style-plain .p-table__td {
	border-bottom: 2px solid $bdGray;
}
@include mq-sp {
	.p-table.style-plain {
		border: none;
	}
	.p-table.style-plain .p-table__th {
		font-size: 12px;
		font-weight: bold;
		padding: 3px 0;
		border-top: none;
		margin-top: 10px;
	}
	.p-table.style-plain .p-table__td {
		border-top: none;
		border-bottom: 2px solid $bdGray;
	}
}

/*---------- リンク・ボタン ----------*/
.p-back-parent {
	display: none;
	@include mq-sp {
		display: block;
		width: 100%;
		background: $bgGray;
		margin-top: 40px;
		padding: 20px;
	}
}
.p-back-parent__button {
	color: $blue;
	font-size: 13px;
	height: 40px;
	line-height: 40px;
	padding-left: 33px;
	display: block;
	border: 1px solid $bdGray;
	background: #FFF;
	position: relative;
	i {
		font-size: 14px;
		width: 14px;
		height: 14px;
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 10px;
		margin: auto;
	}
}

/*---------- カード・ボックス ----------*/
.p-card-gray {
	background: $bgGray;
	padding: 40px;
	border-radius: 10px;
	@include mq-sp {
		padding: 20px;
		border-radius: 5px;
	}
}


/**
* 企業情報
**/
/*---------- 企業情報 ----------*/
.p-company {}
.p-company__header {}
.p-company__index {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 80px;
}
.p-company__content {
	width: 48%;
	margin-left: 4%;
	margin-bottom: 50px;
}
.p-company__content:first-child,
.p-company__content:nth-child(2n+1) {
	margin-left: 0;
}
.p-company__content-inner {
	display: block;
	@include hoverOpacity;
}
.p-company__content-thumb {
	font-size: 0;
	width: 100%;
	img {
		width: 100%;
		height: auto;
		border-radius: 10px 10px 0 0;
	}
}
.p-company__content-title {
	color: $blue;
	@include fontsize(16);
	font-weight: normal;
	padding: 30px 30px 0;
}
.p-company__content-title > i {
	position: relative;
	top: 1px;
}
@include mq-sp {
	.p-company__header {
		padding: 0 20px;
	}
	.p-company__index {
		width: 100%;
		display: block;
		margin-top: 30px;
		padding: 0 20px;
	}
	.p-company__content {
		width: 100%;
		margin-left: 0;
		margin-bottom: 40px;
	}
	.p-company__content:last-child {
		margin-bottom: 10px;
	}
	.p-company__content-thumb {
		img {
			border-radius: 5px;
		}
	}
	.p-company__content-title {
		font-size: 15px;
		padding: 20px 0 0;
	}
	.p-company__content-title > i {
		top: 0;
	}
}
/*---------- 代表挨拶 ----------*/
.p-greetings {
	padding-top: 20px;
}
.p-greetings__header {
	display: flex;
	background: $bgGray;
}
.p-greetings__header-image {
	width: 60%;
	img {
		width: 100%;
		height: auto;
	}
}
.p-greetings__header-text {
	width: 40%;
	position: relative;
}
.p-greetings__profile {
	color: #000;
	font-family: $fontFamilyMin;
	font-weight: normal;
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.p-greetings__profile-small {
	@include fontsize(14);
	display: block;
	text-align: center;
}
.p-greetings__profile-large {
	@include fontsize(30);
	display: block;
	text-align: center;
	margin-top: 10px;
}
.p-greetings__body {
	margin-top: 80px;
}
.p-greetings__slogan {
	color: #000;
	@include fontsize(32);
	font-weight: bold;
	line-height: 1.5;
	margin-bottom: 40px;
}
.p-greetings__message {
	margin-bottom: 90px;
}
.p-greetings__policy {
	margin-top: 20px;
}
@include mq-sp {
	.p-greetings {
		padding: 0 20px;
	}
	.p-greetings__header {
		display: block;
		background: none;
	}
	.p-greetings__header-image {
		width: 100%;
	}
	.p-greetings__header-text {
		width: 100%;
	}
	.p-greetings__profile {
		position: relative;
		top: auto;
		transform: none;
		margin-top: 20px;
	}
	.p-greetings__profile-small {
		font-size: 11px;
	}
	.p-greetings__profile-large {
		font-size: 20px;
		margin-top: 8px;
	}
	.p-greetings__body {
		margin-top: 40px;
	}
	.p-greetings__slogan {
		font-size: 16px;
		margin-bottom: 25px;
	}
	.p-greetings__message {
		margin-bottom: 50px;
	}
	.p-greetings__policy {
		margin-top: 10px;
	}
}

/*---------- 会社概要 ----------*/
.p-profile__table {}
.p-profile__headline-small {
	@include fontsize(14);
	font-weight: normal;
	margin-left: 30px;
	vertical-align: bottom;
}
.p-profile__relationship-content {
	display: flex;
}
.p-profile__relationship-column {
	width: 330px;
	padding-right: 10px;
}
.p-profile__relationship-list {
	li {
		color: #000;
		@include fontsize(14);
		line-height: 20px;
		margin-top: 5px;
	}
}
@include mq-sp {
	.p-profile {
		padding: 0 20px;
	}
	.p-profile__icon-pdf {
		font-size: 13px;
	}
	.p-profile__headline-small {
		font-size: 10px;
		margin-left: 10px;
	}
	.p-profile__relationship-content {
		display: block;
	}
	.p-profile__relationship-column {
		width: 100%;
		padding-right: 0;
	}
	.p-profile__relationship-list {
		li {
			color: $ftGray;
			margin-top: 3px;
		}
	}
}

/*---------- 事業所一覧 ----------*/
.p-office {}
.p-office__section {}
.p-office__office-info {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.p-office__link-map {
	a {
		color: $blue;
		@include fontsize(14);
		@include hoverColor($blue,$hoverBlue);
	}
	i {
		position: relative;
		top: 2px;
	}
}
.p-office__map {
	background: $bgGray;
	margin-top: 30px;
	padding: 20px;
}
.p-office__map-inner {
	width: 100%;
	height: 410px;
}
.p-office__group {
	margin-top: 20px;
	display: flex;
}
.p-office__group-logo {
	position: relative;
	width: 44.75%;
	img {
		width: 100%;
		max-width: 340px;
		height: auto;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
}
.p-office__group-text {
	width: 55.25%;
	padding-left: 45px;
}
.p-office__group-info {
	a {
		color: #000;
		text-decoration: underline;
	}
	a:hover {
		text-decoration: none;
	}
}

@include mq-sp {
	.p-office {
		padding: 0 20px;
	}
	.p-office__office-info {
		display: block;
	}
	.p-office__link-map {
		width: 100%;
		padding: 0 20px;
		margin-top: 15px;
		border: 1px solid $bdGray;
		a {
			font-size: 13px;
			display: block;
			width: 100%;
			height: 40px;
			line-height: 40px;
		}
		i {
			top: 1px;
		}
	}
	.p-office__map {
		margin-top: 20px;
	}
	.p-office__map-inner {
		height: 295px;
	}
	.p-office__group {
		display: block;
	}
	.p-office__group-logo {
		width: 100%;
		text-align: center;
		img {
			max-width: 270px;
			position: static;
			transform: none;
		}
	}
	.p-office__group-text {
		width: 100%;
		padding-left: 0;
		margin-top: 15px;
		padding-top: 20px;
		border-top: 1px solid $bdGray;
	}
	.p-office__group-info {
		a:hover {
			text-decoration: underline;
		}
	}
}

/*---------- CSR ----------*/
.p-csr {}
.p-csr__headline {
	color: #000;
	@include fontsize(30);
	font-weight: bold;
	margin-bottom: 40px;
}
.p-csr__headline-pict {
	width: 36px;
	height: 36px;
	margin-right: 20px;
	vertical-align: middle;
}
.p-csr__image {
	margin-top: 80px;
	width: 100%;
	height: auto;
}
.p-csr__index {
	margin-top: 40px;
	display: flex;
	flex-wrap: wrap;
}
.p-csr__content {
	width: 48.936%;
	margin-left: 2.128%;
	margin-bottom: 20px;
}
.p-csr__content:first-child,
.p-csr__content:nth-child(2n+1) {
	margin-left: 0;
}
.p-csr__content-inner {
	width: 100%;
	height: 100px;
	padding: 20px;
	border: 2px solid $bdGray;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include hoverOpacity;
}
.p-csr__content-header {
	display: flex;
	align-items: center;
}
.p-csr__content-pict {
	width: 36px;
	height: 36px;
	margin-right: 6px;
}
.p-csr__content-title {
	color: #000;
	@include fontsize(16);
	line-height: 1.2;
	font-weight: bold;
}
.p-csr__content-body {
	padding-left: 20px;
	display: flex;
	align-items: center;
	i {
		color: $blue;
		font-size: 17px;
	}
}
.p-csr__content-description {
	color: $ftGray;
	@include fontsize(14);
	line-height: 1.2;
	margin-right: 10px;
}
.p-csr__training {
	width: 100%;
	margin-top: 40px;
}
.p-csr__training-menu {
	width: 100%;
	padding: 0 40px;
}
.p-csr__training-item {
	color: #000;
	@include fontsize(14);
	line-height: 1.2;
	display: block;
	padding: 16px 15px;
	margin-top: 5px;
	border: 1px solid $bdGray;
	display: block;
}
.p-csr__figure {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 40px;
	margin-bottom: -40px;
}
.p-csr__figure-box {
	width: 48%;
	margin-left: 4%;
	margin-bottom: 40px;
	img {
		width: 100%;
		height: auto;
	}
}
.p-csr__figure-box:first-child,
.p-csr__figure-box:nth-child(2n+1) {
	margin-left: 0;
}
.p-csr__figure-caption {
	color: #555;
	@include fontsize(14);
	line-height: 1.3;
	display: block;
	margin-top: 10px;
}
.p-csr__paragraph {
	margin-top: 40px;
	a {
		@include hoverColor;
		i {
			color: $blue;
			position: relative;
			top: 2px;
		}
	}
}
.p-csr__card {
	margin-top: 10px;
}

@include mq-sp {
	.p-csr {
		padding: 0 20px;
	}
	.p-csr__headline {
		font-size: 16px;
		margin-bottom: 20px;
	}
	.p-csr__headline-pict {
		width: 24px;
		height: 24px;
		margin-right: 5px;
	}
		.p-csr__image {
		margin-top: 40px;
	}
	.p-csr__index {
		margin-top: 20px;
		display: block;
	}
	.p-csr__content {
		width: 100%;
		margin-left: 0;
		margin-bottom: 0;
		margin-top: 10px;
	}
	.p-csr__content-inner {
		height: 60px;
		padding: 10px;
		border-radius: 5px;
	}
	.p-csr__content-pict {
		width: 18px;
		height: 18px;
		margin-right: 5px;
	}
	.p-csr__content-title {
		font-size: 12px;
		letter-spacing: -0.05em;
	}
	.p-csr__content-body {
		padding-left: 0;
		i {
			font-size: 15px;
		}
	}
	.p-csr__content-description {
		font-size: 10px;
		margin-right: 5px;
	}
	.p-csr__training {
		margin-top: 20px;
	}
	.p-csr__training-menu {
		padding: 0;
	}
	.p-csr__training-item {
		color: $ftGray;
		font-size: 13px;
		padding: 12px 10px;
	}
	.p-csr__figure {
		margin-top: 20px;
		margin-bottom: -20px;
	}
	.p-csr__figure-box {
		margin-bottom: 20px;
	}
	.p-csr__figure-caption {
		font-size: 13px;
	}
	.p-csr__paragraph {
		margin-top: 20px;
	}
}

/**
* 製品・サービス
**/
/*---------- 製品・サービス ----------*/
.p-service {
	width: 100%;
	padding-top: 20px;
}
.p-service__header {
	width: 100%;
}
.p-service__index {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 80px;
}
.p-service__content {
	width: 48%;
	margin-left: 4%;
	margin-bottom: 50px;
}
.p-service__content:first-child,
.p-service__content:nth-child(2n+1) {
	margin-left: 0;
}
.p-service__content-inner {
	display: block;
	@include hoverOpacity;
}
.p-service__content-header {
	width: 100%;
	height: 170px;
	padding: 40px;
	border: 2px solid $bdGray;
	border-radius: 10px;
	position: relative;
	img {
		width: 280px;
		height: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
}
.p-service__content-body {
	padding: 30px 30px 0;
}
.p-service__content-title {
	color: $blue;
	@include fontsize(16);
	font-weight: normal;
}
.p-service__content-title > i {
	position: relative;
	top: 1px;
}
.p-service__content-intro {
	color: $ftGray;
	@include fontsize(14);
	line-height: 1.8;
	margin-top: 20px;
}
.p-service__headline {
	width: 100%;
	background: $bgGray;
	border: 2px solid $bdGray;
	border-radius: 10px;
	padding: 40px;
	display: flex;
	align-items: center;
}
.p-service__headline-logo {
	width: 50%;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 20px;
	img {
		width: 280px;
		height: auto;
	}
}
.p-service__headline-text {
	width: 50%;
	height: 100px;
	border-left: 1px solid $bdGray;
	display: flex;
	align-items: center;
	padding-left: 20px;
}
.p-service__headline-title {
	display: inline-block;
	width: 100%;
}
.p-service__headline-upper {
	color: #000;
	@include fontsize(22);
	font-weight: bold;
	line-height: 1.2;
	display: block;
	text-align: center;
}
.p-service__headline-lower {
	color: #000;
	@include fontsize(32);
	font-weight: bold;
	line-height: 36px;
	display: block;
	text-align: center;
	margin-top: 10px;
}
.p-service__overview {
	color: #000;
	@include fontsize(16);
	line-height: 1.8;
	margin-top: 40px;
}
.p-service__section {
	margin-top: 100px;
}
.p-service__paragraph {
	margin-top: 40px;
}
.p-service__feature {}
.p-service__feature-box {
	width: 100%;
	margin-top: 40px;
	padding-bottom: 30px;
	border-bottom: 1px solid $bdGray;
}
.p-service__feature-header {
	width: 100%;
	@include clearfix;
}
.p-service__feature-num {
	color: $blue;
	@include fontsize(24);
	font-weight: bold;
	line-height: 30px;
	display: inline-block;
	// padding: 0 2px 7px;
	position: relative;
	float: left;
	// &::after {
	// 	content: "";
	// 	display: block;
	// 	width: 100%;
	// 	height: 3px;
	// 	background: $blue;
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 0;
	// }
}
.p-service__feature-headline {
	color: #000;
	@include fontsize(20);
	font-weight: bold;
	line-height: 1.5;
	float: right;
	padding-right: 40px;
	margin-right: -40px;
	width: 100%;
}
.p-service__feature-body {
	margin-top: 20px;
	padding-left: 40px;
}
.p-service__price {
	width: 100%;
	background: #FFF;
}
.p-service__capture {}
.p-service__capture-images {
	width: 100%;
	padding: 30px;
	background: $bgGray;
	border-radius: 10px;
	display: flex;
	flex-wrap: wrap;
}
.p-service__capture-image {
	width: 33.3333%;
	padding: 10px;
	img {
		width: 100%;
		height: auto;
		box-shadow:0px 0px 20px 1px rgba(#000,0.2);
	}
}
.p-service__capture-images.column-4 > .p-service__capture-image {
	width: 25%;
}
.p-service__system {
	width: 100%;
	background: #FFF;
}
.p-service__system-box {
	width: 100%;
}
.p-service__system-headline {
	color: #000;
	@include fontsize(20);
	font-weight: bold;
	margin: 40px 0;
	padding-left: 40px;
	position: relative;
	&::before {
		content: "";
		display: block;
		width: 20px;
		height: 3px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		margin: auto;
	}
}
.p-service__system-table {
	width: 100%;
	padding-left: 40px;
}
.p-service__system-rowheader {
	display: flex;
	border-top: 1px solid $bdGray;
	border-bottom: 1px solid $bdGray;
}
.p-service__system-row {
	display: flex;
	border-bottom: 1px solid $bdGray;
}
.p-service__system-th {
	width: 190px;
}
.p-service__system-td {
	flex: 1;
}
.p-service__system-rowheader .p-service__system-th,
.p-service__system-rowheader .p-service__system-td {
	color: #FFF;
	@include fontsize(14);
	line-height: 24px;
	text-align: center;
	padding: 16px 20px;
}
.p-service__system-row .p-service__system-th {
	color: #000;
	@include fontsize(14);
	line-height: 24px;
	padding: 28px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.p-service__system-row .p-service__system-td {
	color: #000;
	@include fontsize(14);
	line-height: 24px;
	padding: 28px 20px;
}
// ブルー
.p-service__system-box.color-blue > .p-service__system-headline {
	&::before {
		background: $blue;
	}
}
.p-service__system-box.color-blue .p-service__system-rowheader .p-service__system-th {
	background: #0286c3;
}
.p-service__system-box.color-blue .p-service__system-rowheader .p-service__system-td {
	background: $blue;
}
.p-service__system-box.color-blue .p-service__system-row .p-service__system-th {
	background: #f3f9fd;
}
// グリーン
.p-service__system-box.color-green > .p-service__system-headline {
	&::before {
		background: $green;
	}
}
.p-service__system-box.color-green .p-service__system-rowheader .p-service__system-th {
	background: #62ae28;
}
.p-service__system-box.color-green .p-service__system-rowheader .p-service__system-td {
	background: $green;
}
.p-service__system-box.color-green .p-service__system-row .p-service__system-th {
	background: #f4fdf3;
}
.p-service__configuration {
	width: 100%;
	background: #FFF;
}
.p-service__configuration-image {
	width: 100%;
	img {
		width: 100%;
		height: auto;
	}
}
.p-service__flow {
	width: 100%;
	background: #FFF;
}
.p-service__flow-list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.p-service__flow-chapter {
	border: 2px solid $blue;
	border-radius: 10px;
	text-align: center;
	width: 23.404%;
	margin-left: 2.128%;
	margin-bottom: 20px;
	padding: 40px 20px;
}
.p-service__flow-chapter:nth-child(4n+1) {
	margin-left: 0;
}
.p-service__flow-num {
	color: $blue;
	font-size: 1rem;
	font-weight: bold;
	display: inline-block;
	position: relative;
}
.p-service__flow-text {
	margin-top: 30px;
}
.p-service__flow-title {
	color: #000;
	@include fontsize(16);
	line-height: 24px;
	font-weight: bold;
	text-align: center;
}
.p-service__flow-note {
	color: #000;
	@include fontsize(14);
	line-height: 24px;
	display: block;
	text-align: center;
}
@include mq-sp {
	.p-service {
		padding: 0;
	}
	.p-service__header {
		padding: 0 20px;
	}
	.p-service__index {
    display: block;
    margin-top: 30px;
    padding: 0 20px;
	}
	.p-service__content {
		width: 100%;
    margin-left: 0;
    margin-bottom: 40px;
	}
	.p-service__content-header {
		height: 150px;
		padding: 20px;
		border-radius: 5px;
		img {
			width: 187px;
		}
	}
	.p-service__content-body {
		padding: 20px 0 0;
	}
	.p-service__content-title {
		font-size: 15px;
	}
	.p-service__content-title > i {
		top: 0;
	}
	.p-service__content-intro {
		color: #000;
		font-size: 13px;
	}
	.p-service__headline {
		width: auto;
		border-radius: 5px;
		padding: 20px;
		display: block;
		margin: 0 20px;
	}
	.p-service__headline-logo {
		width: 100%;
		height: auto;
		margin-bottom: 15px;
		img {
			width: 195px;
			height: auto;
		}
	}
	.p-service__headline-text {
		width: 100%;
		height: auto;
		border-left: none;
		border-top: 1px solid $bdGray;
		display: block;
		padding-left: 0;
		padding-top: 15px;
	}
	.p-service__headline-title {
		display: block;
		width: auto;
	}
	.p-service__headline-upper {
		font-size: 15px;
	}
	.p-service__headline-lower {
		font-size: 22px;
		line-height: 32px;
		margin-top: 5px;
	}
	.p-service__overview {
		font-size: 13px;
		margin-top: 20px;
		padding: 0 20px;
	}
	.p-service__paragraph {
		margin-top: 20px;
	}
	.p-service__section {
		margin-top: 40px;
		padding: 0 20px;
	}
	.p-service__feature-box {
		margin-top: 20px;
		padding-bottom: 15px;
	}
	.p-service__feature-num {
		font-size: 18px;
		line-height: 21px;
		padding: 0;
	}
	.p-service__feature-headline {
		font-size: 14px;
		padding-right: 20px;
		margin-right: -20px;
		// padding-right: 30px;
		// margin-right: -30px;
	}
	.p-service__feature-body {
		padding-left: 0;
	}
	.p-service__capture-images {
		padding: 0;
		background: none;
		border-radius: 0;
		display: block;
	}
	.p-service__capture-image {
		width: 100%;
		padding: 0;
		margin-top: 15px;
		img {
			box-shadow:0px 0px 10px 1px rgba(#000,0.2);
		}
	}
	.p-service__capture-images.column-4 {
		width: calc(100% + 20px);
		margin-left: -10px;
		display: flex;
    flex-wrap: wrap;
	}
	.p-service__capture-images.column-4 > .p-service__capture-image {
		width: 50%;
		padding: 10px;
		margin-top: 0;
	}
	.p-service__system-headline {
		font-size: 14px;
		margin: 20px 0;
		padding-left: 15px;
		&::before {
			width: 10px;
		}
	}
	.p-service__system-table {
		padding-left: 0;
	}
	.p-service__system-rowheader {
		display: none;
	}
	.p-service__system-row {
		display: block;
		border-left: 1px solid $bdGray;
		border-right: 1px solid $bdGray;
		border-bottom: 1px solid $bdGray;
	}
	.p-service__system-th {
		width: 100%;
	}
	.p-service__system-td {
		width: 100%;
	}
	.p-service__system-row .p-service__system-th {
		font-size: 13px;
		line-height: 18px;
		padding: 6px 10px;
		display: block;
	}
	.p-service__system-row .p-service__system-td {
		color: $ftGray;
		font-size: 13px;
		line-height: 1.8;
		padding: 5px 10px;
	}
	// ブルー
	.p-service__system-box.color-blue .p-service__system-row .p-service__system-th {
		color: #FFF;
		background: #0286c3;
	}
	// グリーン
	.p-service__system-box.color-green .p-service__system-row .p-service__system-th {
		color: #FFF;
		background: #62ae28;
	}
	.p-service__flow-chapter {
		border-radius: 5px;
		width: calc(50% - 5px);
		margin-left: 10px;
		margin-bottom: 10px;
		padding: 20px 10px;
	}
	.p-service__flow-chapter:first-child,
	.p-service__flow-chapter:nth-child(2n+1) {
		margin-left: 0;
	}
	.p-service__flow-num {
		// font-size: 18px;
		font-size: 12px;
	}
	.p-service__flow-text {
		margin-top: 10px;
	}
	.p-service__flow-title {
		font-size: 13px;
		line-height: 1.5;
	}
	.p-service__flow-note {
		color: $ftGray;
		font-size: 12px;
		line-height: 1.5;
	}
}

/*---------- 製品ページ改修 2020.3.21 ----------*/

.p-service-content {
	@include fontsize(14);
	line-height: 1.7;
	img {
		vertical-align: bottom;
		-ms-interpolation-mode: bicubic;
		max-width: 100%;
		height: auto;
	}
	p:not([class]) + p:not([class]) {
		margin-top: 1em;
	}
	.c-headline-icon {
		margin: 60px 0 30px;
	}
	.c-headline-bar {
		margin: 40px 0 20px;
	}
	.p-text-small {
		color: #000;
	}
	@include mq-sp {
		font-size: 13px;
		.c-headline-icon {
			margin: 40px 0 20px;
		}
		.c-headline-bar {
			margin: 20px 0 10px;
		}
	}
}

.p-service__heading {
	text-align: center;
	margin-bottom: 20px;
	@include mq-sp {}
}
.p-service__heading-en {
	font-family: $fontOswald;
	color: #DEEFF5;
	@include fontsize(50);
	font-weight: bold;
	text-align: center;
	line-height: 30px;
	@include mq-sp {
		font-size: 36px;
		line-height: 20px;
	}
}
.p-service__heading-ja {
	@include fontsize(26);
	font-weight: bold;
	line-height: 1;
	text-align: center;
	@include mq-sp {
		font-size: 18px;
	}
}

.p-service__hero {
	width: 100%;
	position: relative;
	@include mq-sp {}
}
.p-service__hero-image {}
.p-service__hero-cta {
	display: block;
	width: 153px;
	border-radius: 50%;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
	background-color: #fff;
	position: absolute;
	right: 25px;
	bottom: 25px;
	img {
		@include hoverOpacity;
	}
	@include mq-sp {
		width: 120px;
		right: 20px;
		bottom: -20px;
	}
}
.p-service__intro {
	margin-top: 40px;
	@include mq-sp {
		padding: 0 20px;
	}
}
.p-service__intro-heading {
	color: #fff;
	@include fontsize(32);
	font-weight: bold;
	line-height: 1;
	display: table;
	text-align: center;
	margin: 0 auto 1.5em;
	padding: 20px 44px;
	background-color: $blue;
	border-radius: 40px;
	position: relative;
	&::after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 18px 31px 0 31px;
		border-color: $blue transparent transparent transparent;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		transform: translateY(100%);
	}
	@include mq-sp {
		font-size: 18px;
		padding: 12px 24px;
		&::after {
			border-width: 9px 14px 0 14px;
		}
	}
}
.p-service__intro-body {
	color: $blue;
	@include fontsize(26);
	font-weight: bold;
	text-align: center;
	@include mq-sp {
		font-size: 16px;
	}
}
.p-service__features {
	margin: 80px 0 0;
	padding-bottom: 60px;
	@include mq-sp {
		margin-top: 40px;
		padding: 0 20px 30px;
	}
}
.p-service__features-body {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
	@include mq-sp {
		display: block;
		margin-top: 20px;
	}
}
.p-service__features-item {
	width: calc(33.3333% - 20px);
	@include mq-sp {
		width: 100%;
		margin-top: 20px;
	}
}
.p-service__features-thumb {
	width: 114px;
	height: 114px;
	margin: 0 auto;
	border-radius: 50%;
	background: linear-gradient(135deg, #50d9ff 0%, #003afa 100%);
	position: relative;
	&::before {
		content: "";
		display: block;
		width: calc(100% - 4px);
		height: calc(100% - 4px);
		background-color: #fff;
		border-radius: 50%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
	> img {
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
	@include mq-sp {}
}
.p-service__features-title {
	@include fontsize(18);
	font-weight: bold;
	line-height: 1.3333;
	text-align: center;
	margin-top: 30px;
	@include mq-sp {
		font-size: 15px;
	}
}
.p-service__features-description {
	margin-top: 30px;
	@include mq-sp {
		margin-top: 15px;
	}
}
.p-service__features-more {
	margin-top: 20px;
	@include mq-sp {
		margin-top: 10px;
	}
}
.p-service__features-link {
	color: $ftBlue;
	display: flex;
	align-items: center;
	@include hoverColor($blue);
	@include mq-sp {
		justify-content: center;
	}
}
.p-service__case {
	background-color: $bgGray;
	padding: 60px 0;
	@include mq-sp {
		padding: 40px 20px;
	}
}
.p-service__case-box {
	padding: 40px;
	background-color: #fff;
	border: 3px solid $bdGray;
	margin-top: 40px;
	& + & {
		margin-top: 20px;
	}
	&.is-accordion-open {}
	@include mq-sp {
		padding: 20px 20px 60px;
		border-width: 2px;
		margin-top: 20px;
		position: relative;
	}
}
.p-service__case-header {
	display: flex;
	align-items: center;
	@include mq-sp {
		display: block;
	}
}
.p-service__case-intro {
	flex: 1;
	padding-right: 30px;
	@include mq-sp {
		padding-right: 0;
	}
}
.p-service__case-title {
	@include fontsize(20);
	font-weight: bold;
	line-height: 1.5;
	@include mq-sp {
		font-size: 16px;
	}
}
.p-service__case-category {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
	> li {
		line-height: 1;
		margin-right: 5px;
		padding: 5px 6px;
		background-color: #EAF9FF;
		border: 1px solid #97CFE3;
		border-radius: 5px;
	}
	@include mq-sp {
		> li {
			border-radius: 3px;
		}
	}
}
.p-service__case-button {
	color: $blue;
	line-height: 1;
	display: inline-block;
	text-align: center;
	width: 120px;
	padding: 10px;
	border-radius: 20px;
	border: 1px solid $blue;
	cursor: pointer;
	@include mq-pc {
		transition: color .2s, background-color .2s;
		&:hover {
			color: #fff;
			background-color: $blue;
		}
	}
	@include mq-sp {
		width: 160px;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 10px;
		margin: auto;
	}
}
.p-service__case-open {
	.is-accordion-open & {
		display: none;
	}
}
.p-service__case-close {
	display: none;
	.is-accordion-open & {
		display: block;
	}
}
.p-service__case-body {
	padding-top: 40px;
	display: none;
	opacity: 0;
	transition: opacity .5s;
	.is-accordion-open & {
		opacity: 1;
	}
	p {
		margin-top: 30px;
	}
	img {
		max-width: 820px;
		display: block;
		margin: 0 auto;
		margin-top: 30px;
	}
	.p-table {
		margin-top: 30px;
	}
	@include mq-sp {
		padding-top: 20px;
		p {
			margin-top: 15px;
		}
		img {
			max-width: 100%;
			margin-top: 15px;
		}
		.p-table {
			margin-top: 15px;
		}
	}
}
.p-service__case-summary {
	display: flex;
	width: 100%;
	dt {
		color: #fff;
		@include fontsize(18);
		font-weight: bold;
		line-height: 1.5;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 72px;
		padding: 10px;
	}
	dd {
		color: #575757;
		@include fontsize(16);
		font-weight: bold;
		line-height: 1.5;
		flex: 1;
		display: flex;
		align-items: center;
		border-width: 1px;
		border-style: solid;
		padding: 15px 20px;
	}
	&.p-service__case-summary--problem {
		dt {
			background-color: #C7C7C7;
		}
		dd {
			border-color: #C7C7C7;
		}
	}
	&.p-service__case-summary--solution {
		dt {
			background-color: #7ED2F0;
		}
		dd {
			border-color: #7ED2F0;
		}
	}
	&.p-service__case-summary--result {
		dt {
			background-color: $blue;
		}
		dd {
			color: $blue;
			border-color: $blue;
		}
	}
	& + & {
		margin-top: 10px;
	}
	@include mq-sp {
		display: block;
		dt {
			font-size: 13px;
			width: 100%;
			padding: 5px 10px;
		}
		dd {
			font-size: 13px;
			display: block;
			padding: 5px 10px;
		}
	}
}
.p-service__details {
	margin-top: 60px;
	@include mq-sp {
		margin-top: 40px;
	}
}
.p-service__details-menu {
	display: flex;
	li {
		flex-basis: 0;
    flex-grow: 1;
		word-break: break-word;
    overflow-wrap: break-word;
		padding: 0 1px;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
	a {
		color: $blue;
		@include fontsize(16);
		line-height: 1.5;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 23px 10px;
		text-align: center;
		background-color: #EAF9FF;
		@include mq-pc {
			transition: color .2s, background-color .2s;
			&:hover {
				color: #fff;
				background-color: $blue;
			}
		}
	}
	@include mq-sp {
		overflow-x: scroll;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		&::before,
		&::after {
			content: "";
			display: block;
			flex: 0 0 20px;
		}
		li {
			flex: 0 0 80px;
			// width: 80px;
		}
		a {
			font-size: 13px;
		}
	}
}
.p-service__details-body {
	> p {
		margin-top: 30px;
	}
	> ul {
		margin: 30px 0;
	}
	@include mq-sp {
		padding: 0 20px;
		> p {
			margin-top: 15px;
		}
		> ul {
			margin: 15px 0;
		}
	}
}
.p-service__details-price {
	color: $blue;
	@include fontsize(16);
	font-weight: bold;
	line-height: 1;
	@include mq-sp {
		font-size: 14px;
	}
}
.p-service__flow-table {
	margin: 40px 0;
	@include mq-sp {
		margin: 20px 0;
	}
}
.p-service__flow-row {
	display: flex;
	border-top: 1px solid $bdGray;
	border-bottom: 1px solid $bdGray;
	dt {
		color: $blue;
		font-weight: bold;
		line-height: 1.3;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 146px;
		padding: 25px 10px;
		background-color: #EAF9FF;
		position: relative;
		&::after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 15px 10px 0 10px;
			border-color: $blue transparent transparent transparent;
			position: absolute;
			left: 0;
			right: 0;
			top: -7px;
			margin: auto;
			transform: translateY(-100%);
		}
	}
	&:first-child dt {
		&::after {
			content: none;
		}
	}
	dd {
		line-height: 1.3;
		flex: 1;
		display: flex;
		align-items: center;
		padding: 25px 20px;
	}
	& + & {
		margin-top: 30px;
	}
	@include mq-sp {
		display: block;
		border: 1px solid $bdGray;
		position: relative;
		&::after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 15px 10px 0 10px;
			border-color: $blue transparent transparent transparent;
			position: absolute;
			left: 0;
			right: 0;
			top: -7px;
			margin: auto;
			transform: translateY(-100%);
		}
		&:first-child {
			&::after {
				content: none;
			}
		}
		dt {
			width: 100%;
			padding: 10px;
			&::after {
				content: none;
			}
		}
		dd {
			padding: 10px;
		}
	}
}
.p-service__trial {
	margin-top: 60px;
	background-color: $bgGray;
	border: 1px solid $bdGray;
	border-radius: 10px;
	padding: 30px 40px;
	@include mq-sp {
		margin: 40px 20px 0;
		border-radius: 5px;
		padding: 20px;
	}
}
.p-service__trial-title {
	color: $blue;
	@include fontsize(24);
	font-weight: bold;
	text-align: center;
	line-height: 1.5;
	text-align: center;
	margin-bottom: 30px;
	@include mq-sp {
		font-size: 16px;
		margin-bottom: 15px;
	}
}
.p-service__trial-contents {
	display: flex;
	margin-top: 20px;
	dt {
		font-weight: bold;
		width: 138px;
	}
	dd {
		flex: 1;
	}
	@include mq-sp {
		display: block;
		margin-bottom: 10px;
		dt {
			width: 100%;
		}
	}
}
.p-service__cta {
	margin-top: 60px;
	display: flex;
	border: 1px solid #DEEFF5;
	border-radius: 10px;
	overflow: hidden;
	@include mq-sp {
		margin-top: 40px;
		padding: 0 20px;
		display: block;
	}
}
.p-service__cta-column {
	flex-basis: 0;
	flex-grow: 1;
	word-break: break-word;
	overflow-wrap: break-word;
	padding: 40px;
	&.p-service__cta-download {
		background-color: #F7FDFF;
		// border-radius: 10px 0 0 10px;
	}
	&.p-service__cta-contact {
		background-color: #D0F3FF;
		border-left: none;
		// border-radius: 0 10px 10px 0;
	}
	@include mq-sp {
		width: 100%;
		padding: 20px;
		&.p-service__cta-download {
			border-radius: 5px 5px 0 0;
		}
		&.p-service__cta-contact {
			border-left: 1px solid #DEEFF5;
			border-top: none;
			border-radius: 0 0 5px 5px;
		}
	}
}
.p-service__cta-description {
	text-align: center;
}
.p-service__cta-link {
	margin-top: 20px;
	text-align: center;
	@include mq-sp {
		margin-top: 10px;
	}
}
.p-service__cta-button {
	font-weight: bold;
	line-height: 1;
	display: inline-block;
	text-align: center;
	width: 200px;
	padding: 20px;
	border-radius: 8px;
	border: 2px solid;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	position: relative;
	.p-service__cta-download & {
		color: $blue;
		background-color: #EAF9FF;
		border-color: #7ED2F0;
	}
	.p-service__cta-contact & {
		color: #fff;
		background-color: #00C8FF;
		border-color: #DEEFF5;
	}
	.icon-angle_right {
		font-size: 14px;
		height: 14px;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 20px;
		margin: auto;
	}
	@include mq-pc {
		transition: transform .2s, box-shadow .2s;
		&:hover {
			transform: translateY(2px);
			box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.16);
		}
	}
	@include mq-sp {
		width: 100%;
		padding: 15px 20px;
		border-radius: 4px;
		border-width: 1px;
		box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
	}
}
.p-service__navi {
	margin-top: 60px;
	padding: 80px 0 100px;
	background-color: $bgGray;
	@include mq-sp {
		margin-top: 40px;
		padding: 40px 20px;
	}
}
.p-service__navi-body {
	display: flex;
	justify-content: space-between;
	margin-top: 60px;
	@include mq-sp {
		display: block;
		margin-top: 40px;
	}
}
.p-service__navi-item {
	width: calc(33.3333% - 20px);
	@include mq-sp {
		width: 100%;
		& + & {
			margin-top: 20px;
		}
	}
}
.p-service__navi-link {
	display: block;
}
.p-service__navi-thumb {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 115px;
	background-color: #fff;
	border: 1px solid $bdGray;
	img {
		width: 160px;
	}
	@include mq-sp {
		height: 90px;
		img {
			width: 120px;
		}
	}
}
.p-service__navi-title {
	color: #000;
	@include fontsize(18);
	font-weight: bold;
	line-height: 1.5;
	margin-top: 20px;
	text-align: center;
	transition: color .2s;
	@include mq-sp {
		font-size: 15px;
		margin-top: 10px;
	}
}
.p-service__navi-description {
	color: #000;
	margin-top: 15px;
	transition: color .2s;
	@include mq-sp {
		margin-top: 5px;
	}
}
@include mq-pc {
	.p-service__navi-link:hover {
		.p-service__navi-title,
		.p-service__navi-description {
			color: $ftBlue;
		}
	}
}

.p-service__cta-sticky {
	display: flex;
	position: fixed;
	right: 20px;
	bottom: 20px;
	opacity: 0;
	pointer-events: none;
	transform: translateY(100px);
	transition: opacity .3s, transform .5s;
	&.is-sticky-button-fixed {
		pointer-events: auto;
		opacity: 1;
		transform: translateY(0);
	}
	.p-service__cta-button {
		width: 170px;
		margin-left: 10px;
		text-indent: -1em;
		.icon-angle_right {
			right: 12px;
		}
	}
	@include mq-sp {
		justify-content: space-between;
		width: 100%;
		right: 0;
		bottom: 0;
		padding: 0 10px 10px;
		.p-service__cta-download,
		.p-service__cta-contact {
			width: calc(50% - 5px);
		}
		.p-service__cta-button {
			width: 100%;
			margin-left: 0;
		}
	}
}


/**
* お知らせ
**/
/*---------- アーカイブ ----------*/
.p-information {
	width: 100%;
	padding-top: 20px;
}
.p-information__index-header {
	width: 100%;
	background: #FFF;
}
.p-information__index-tabs {
	width: 100%;
	display: flex;
	border: 1px solid $bdGray;
}
.p-information__index-tab {
	@include fontsize(14);
	font-weight: bold;
	width: calc(100% / 4);
	height: 40px;
	line-height: 40px;
	text-align: center;
	background: $bgGray;
	border-left: 1px solid $bdGray;
	a {
		color: $ftGray;
		text-align: center;
		display: block;
		width: 100%;
		@include hoverOpacity;
	}
}
.p-information__index-tab.is-active {
	color: #FFF;
	background:$blue;
}
.p-information__index-tab:first-child {
	border-left: none;
}
.p-information__index-body {
	width: 100%;
	background: #FFF;
}
.p-information__index-contents {
	width: 100%;
	margin-top: 40px;
}
.p-information__index-pagenavi {
	width: 100%;
	margin-top: 80px;
}
.p-posts-index {}
.p-posts-index__post {
	padding: 22px 0;
	border-bottom: 1px solid #e5e5e5;
}
.p-posts-index__link {
	display: flex;
}
.p-posts-index__meta {
	font-size: 0;
	width: 235px;
	padding-right: 20px;
}
.p-posts-index__date {
	color: #000;
	@include fontsize(13);
	font-weight: bold;
	line-height: 28px;
	display: inline-block;
	width: 9.5em;
	vertical-align: top;
}
.p-posts-index__category {
	color: $blue;
	@include fontsize(11);
	background: #FFF;
	border: 2px solid $blue;
	display: inline-block;
	text-align: center;
	width: 84px;
	height: 28px;
	line-height: 24px;
	overflow: hidden;
	border-radius: 14px;
}
.p-posts-index__title {
	color: $blue;
	@include fontsize(14);
	font-weight: normal;
	line-height: 28px;
	flex: 1;
	transition: .2s;
}
.p-posts-index__link:hover > .p-posts-index__title {
	color: $hoverBlue;
}
.p-pagenavi {
	font-size: 0;
	width: 100%;
	text-align: center;
}
.p-pagenavi__prev,
.p-pagenavi__next,
.p-pagenavi__number {
	color: #FFF;
	@include fontsize(14);
	display: inline-block;
	width: 45px;
	height: 50px;
	line-height: 50px;
	background: $blue;
	margin: 0 10px;
	text-align: center;
	@include hoverOpacity;
}
.p-pagenavi__number.is-current {
	color: $blue;
	line-height: 46px;
	background: #FFF;
	border: 2px solid $blue;
}
.p-information__post-header {
	width: 100%;
}
.p-information__post-meta {
	font-size: 0;
	width: 100%;
	display: flex;
	align-items: center;
}
.p-information__post-category {
	color: $blue;
	@include fontsize(11);
	background: #FFF;
	border: 2px solid $blue;
	display: inline-block;
	text-align: center;
	width: 84px;
	height: 28px;
	line-height: 24px;
	overflow: hidden;
	border-radius: 14px;
}
.p-information__post-date {
	color: #000;
	@include fontsize(13);
	font-weight: bold;
	display: inline-block;
	margin-left: 10px;
}
.p-information__post-title {
	color: #000;
	@include fontsize(28);
	font-weight: bold;
	line-height: 1.5;
	width: 100%;
	margin-top: 40px;
}
.p-information__post-eyecatch {
	width: 100%;
	margin-top: 75px;
	overflow: hidden;
	img {
		width: 100%;
		height: auto;
	}
}
.p-information__post-body {
	width: 100%;
	overflow: hidden;
	margin-top: 40px;
	a {
		color: $ftGray;
		text-decoration: underline;
	}
	a:hover {
		text-emphasis: none;
	}
	p,
	ul,
	ol {
		margin-bottom: 40px;
	}
	li {
		margin-top: 10px;
	}
	h2 {
		margin: 80px 0 40px;
	}
	.p-table {
		margin-bottom: 40px;
	}
	.p-table p,
	.p-table ul,
	.p-table ol {
		margin-bottom: 0;
	}
	p,
	li {
		color: $ftGray;
		@include fontsize(14);
		line-height: 1.8;
	}
	h2 {
		color: #000;
    @include fontsize(20);
		font-weight: bold;
		line-height: 1.5;
    padding-left: 40px;
		position: relative;
		&::before {
			content: "";
			display: block;
			width: 20px;
			height: 3px;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			background: $blue;
		}
	}
	a {
		text-decoration: underline;
	}
	a:hover {
		text-decoration: none;
	}
}
.p-information__post-footer {
	width: 100%;
	margin-top: 100px;
}
.p-postnavi {
	display: flex;
	justify-content: space-between;
}
.p-postnavi__box {
	display: flex;
	align-items: center;
}
.p-postnavi__button {
	color: #FFF;
	font-size: 14px;
	display: inline-block;
	width: 45px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	background: $blue;
	@include hoverOpacity;
}
.p-postnavi__link {
	font-size: 0;
}
.p-postnavi__link.is-back {
	margin-left: 20px;
}
.p-postnavi__link.is-next {
	margin-right: 20px;
}
a.p-postnavi__link-text {
	color: $ftGray;
	@include fontsize(14);
	text-decoration: underline;
}
a.p-postnavi__link-text:hover {
	text-decoration: none;
}
.p-postnavi__link-separator {
	color: #DDD;
	@include fontsize(14);
	margin: 0 0.7em;
}
@include mq-sp {
	.p-information {
		padding-top: 0;
	}
	.p-information__index-tab {
		font-size: 11px;
	}
	.p-information__index-body {
		padding: 0 20px;
	}
	.p-information__index-contents {
		margin-top: 20px;
	}
	.p-information__index-pagenavi {
		margin-top: 40px;
	}
	.p-posts-index__post {
		padding: 20px 0;
	}
	.p-posts-index__link {
		display: block;
	}
	.p-posts-index__meta {
		width: 100%;
		padding-right: 0;
		display: flex;
    align-items: center;
	}
	.p-posts-index__date {
		font-size: 12px;
		line-height: 1;
		width: auto;
		margin-left: 10px;
    order: 2;
	}
	.p-posts-index__category {
		font-size: 10px;
		width: 80px;
		height: 24px;
		line-height: 20px;
		border-radius: 12px;
		order: 1;
	}
	.p-posts-index__title {
		font-size: 13px;
		line-height: 1.8;
		margin-top: 15px;
		transition: none;
	}
	.p-posts-index__link:hover > .p-posts-index__title {
		color: $blue;
	}
	.p-pagenavi {
		font-size: 0;
		width: calc(100% + 13px);
		margin-left: -13px;
		text-align: center;
	}
	.p-pagenavi__prev,
	.p-pagenavi__next,
	.p-pagenavi__number {
		color: #FFF;
		@include fontsize(14);
		display: inline-block;
		width: calc(100% / 7 - 13px);
		margin-left: 13px;
		margin-right: 0;
		height: 40px;
		line-height: 40px;
	}
	.p-pagenavi__number.is-current {
		line-height: 36px;
	}
	.p-information__post-header {
		padding: 0 20px;
	}
	.p-information__post-category {
		font-size: 10px;
		width: 80px;
		height: 24px;
		line-height: 20px;
		border-radius: 12px;
	}
	.p-information__post-date {
		font-size: 12px;
	}
	.p-information__post-title {
		font-size: 16px;
		margin-top: 20px;
	}
	.p-information__post-eyecatch {
		width: 100%;
		margin-top: 35px;
	}
	.p-information__post-body {
		margin-top: 20px;
		padding: 0 20px;
		a:hover {
			text-decoration: underline;
		}
		p,
		ul,
		ol {
			margin-bottom: 20px;
		}
		li {
			margin-top: 5px;
		}
		h2 {
			margin: 40px 0 20px;
		}
		.p-table {
			margin-bottom: 20px;
		}
		.p-table p,
		.p-table ul,
		.p-table ol {
			margin-bottom: 0;
		}
		p,
		li {
			font-size: 13px;
		}
		h2 {
			font-size: 14px;
			margin: 20px 0;
			padding-left: 15px;
			&::before {
				width: 10px;
			}
		}
		a:hover {
			text-decoration: underline;
		}
	}
	.p-information__post-footer {
		margin-top: 50px;
		padding: 0 20px;
	}
	.p-postnavi__button {
		font-size: 11px;
		width: 36px;
		height: 40px;
		line-height: 40px;
	}
	.p-postnavi__link.is-back {
		margin-left: 10px;
	}
	.p-postnavi__link.is-next {
		margin-right: 10px;
	}
	a.p-postnavi__link-text {
		font-size: 12px;
	}
	a.p-postnavi__link-text:hover {
		text-decoration: underline;
	}
	.p-postnavi__link-separator {
		font-size: 12px;
		margin: 0 0.2em;
	}
}

/**
* ソリューション
**/
.p-solution {
	width: 100%;
	background: #FFF;
}
.p-solution__header {
	width: 100%;
	max-width: calc(1000px + 80px);
	margin: 0 auto;
	padding: 0 40px;
}
.p-solution__header-text {
	color: #000;
	@include fontsize(30);
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
}
.p-solution__navi {
	width: 100%;
	max-width: calc(1000px + 80px);
	margin: 80px auto 0;
	padding: 0 40px;
}
.p-anchornavi {}
.p-anchornavi__inner {
	border: 1px solid $bdGray;
	display: flex;
}
.p-anchornavi__item {
	width: calc(100% / 3);
	height: 60px;
	padding: 0 20px;
	border-left: 1px solid $bdGray;
	text-align: center;
	position: relative;
	@include hoverOpacity;
}
.p-anchornavi__item:first-child {
	border-left: none;
}
.p-anchornavi__pict {
	width: 36px;
	height: auto;
	display: inline-block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
}
.p-anchornavi__text {
	color: #000;
	@include fontsize(14);
	font-weight: bold;
	line-height: 58px;
}
.p-anchornavi__icon {
	color: $blue;
	font-size: 17px;
	width: 17px;
	height: 17px;
	display: inline-block;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 20px;
	margin: auto;
}
.p-solution__body {
	width: 100%;
	margin-top: 80px;
}
.p-solution__section {
	width: 100%;
	margin-top: 100px;
	padding: 40px 0;
	position: relative;
}
.p-solution__section:first-child {
	margin-top: 0;
}
.p-solution__background {
	width: 58.3333%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	z-index: 1;
}
.p-solution__background.position-reverse {
	left: auto;
	right: 0;
}
.p-solution__background.is-infrastructure {
	background-image: url(../images/solution/infrastructure_background.jpg);
}
.p-solution__background.is-businesssystem {
	background-image: url(../images/solution/businesssystem_background.jpg);
}
.p-solution__background.is-controlsystem {
	background-image: url(../images/solution/controlsystem_background.jpg);
}
.p-solution__content {
	@include contPad;
	max-width: 1280px;
	position: relative;
	z-index: 10;
}
.p-solution__content-inner {
	margin-left: 37.5%;
	background: #FFF;
	padding: 80px 0 80px 80px;
}
.p-solution__content-inner.position-reverse {
	margin-left: auto;
	margin-right: 37.5%;
	padding: 80px 80px 80px 0;
}
.p-solution__content-headline {
	color: #000;
	@include fontsize(28);
	font-weight: bold;
	padding-left: 50px;
	position: relative;
}
.p-solution__content-pict {
	width: 36px;
	height: auto;
	display: inline-block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}
.p-solution__content-description {
	color: #000;
	@include fontsize(14);
	line-height: 1.8;
	margin-top: 40px;
}
.p-solution__content-menu {
	width: 100%;
	margin-top: 30px;
}
.p-solution__content-list {
	color: $ftGray;
	@include fontsize(13);
	line-height: 20px;
	margin-top: 5px;
	text-indent: -1em;
	padding-left: 1em;
}
@include mq-sp {
	.p-solution__header {
		max-width: initial;
		padding: 0 20px;
	}
	.p-solution__header-text {
		font-size: 16px;
		text-align: left;
	}
	.p-solution__navi {
		max-width: initial;
		margin: 40px auto 0;
		padding: 0;
	}
	.p-anchornavi__inner {
		border-left: none;
		border-right: none;
	}
	.p-anchornavi__item {
		height: auto;
		padding: 10px 0;
	}
	.p-anchornavi__pict {
		width: 27px;
		position: relative;
		top: auto;
		bottom: auto;
		left: 4px;
	}
	.p-anchornavi__text {
		font-size: 11px;
		line-height: 1.3;
		display: block;
		width: 100%;
		margin-top: 5px;
		letter-spacing: -0.05em;
	}
	.p-anchornavi__icon {
		display: none;
	}
	.p-solution__body {
		margin-top: 40px;
	}
	.p-solution__section {
		margin-top: 40px;
		padding: 0;
		position: relative;
	}
	.p-solution__background {
		width: 100%;
		height: auto;
		position: relative;
		top: auto;
		left: auto;
		padding-top: 47.3333%;
	}
	.p-solution__background.position-reverse {
		right: auto;
	}
	.p-solution__background.is-infrastructure {
		background-image: url(../images/solution/infrastructure_background-sp.jpg);
	}
	.p-solution__background.is-businesssystem {
		background-image: url(../images/solution/businesssystem_background-sp.jpg);
	}
	.p-solution__background.is-controlsystem {
		background-image: url(../images/solution/controlsystem_background-sp.jpg);
	}
	.p-solution__content {
		width: 100%;
		max-width: initial;
		padding: 0 20px;
		margin-top: 40px;
	}
	.p-solution__content-inner {
		margin-left: auto;
		padding: 0;
	}
	.p-solution__content-inner.position-reverse {
		margin-right: initial;
		padding: 0;
	}
	.p-solution__content-headline {
		font-size: 16px;
		padding-left: 35px;
	}
	.p-solution__content-pict {
		width: 27px;
	}
	.p-solution__content-description {
		font-size: 13px;
		margin-top: 20px;
	}
	.p-solution__content-menu {
		margin-top: 15px;
	}
	.p-solution__content-list {
		font-size: 12px;
		line-height: 16px;
		margin-top: 5px;
	}
}

/**
* 開発実績
**/
.p-results {}
.p-results__header {}
.p-results__headline {
	color: #000;
	@include fontsize(32);
	font-weight: bold;
	margin-bottom: 40px;
	text-align: center;
}
.p-results__body {}
.p-results__list {
	border-left: 1px solid $bdGray;
	display: flex;
	flex-wrap: wrap;
}
.p-results__column {
	color: #000;
	@include fontsize(14);
	line-height: 22px;
	width: calc(100% / 3);
	padding: 20px 10px;
	text-align: center;
	border-right: 1px solid $bdGray;
	border-bottom: 1px solid $bdGray;
}
.p-results__column:nth-child(1),
.p-results__column:nth-child(2),
.p-results__column:nth-child(3) {
	border-top: 1px solid $bdGray;
}
.p-results__column:nth-child(6n+1),
.p-results__column:nth-child(6n+2),
.p-results__column:nth-child(6n+3) {
	background: $bgGray;
}
@include mq-sp {
	.p-results__header {
		padding: 0 20px;
	}
	.p-results__headline {
		font-size: 16px;
		margin-bottom: 20px;
	}
	.p-results__body {
		padding: 0 20px;
	}
	.p-results__list {
		border-top: 1px solid $bdGray;
		border-right: 1px solid $bdGray;
		display: block;
		width: 100%;
	}
	.p-results__column {
		color: $ftGray;
		font-size: 13px;
		line-height: 1.8;
    padding: 8px 15px;
		width: 100%;
		text-align: left;
		border-right: none;
	}
	.p-results__column:nth-child(1),
	.p-results__column:nth-child(2),
	.p-results__column:nth-child(3) {
		border-top: none;
	}
		.p-results__column:nth-child(6n+1),
	.p-results__column:nth-child(6n+2),
	.p-results__column:nth-child(6n+3) {
		background: none;
	}
		.p-results__column:nth-child(odd) {
		background: $bgGray;
	}
}

/**
* お問い合わせ
**/
.p-contact {
	width: 100%;
	padding-top: 20px;
	background: #FFF;
}
.p-contact__tabs {
	width: 100%;
	display: flex;
	border: 1px solid $bdGray;
}
.p-contact__tab {
	@include fontsize(14);
	font-weight: bold;
	flex: 1 1 0;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background: $bgGray;
	border-left: 1px solid $bdGray;
	a {
    color: $ftGray;
		text-align: center;
		display: block;
		width: 100%;
		opacity: 1;
		@include hoverOpacity;
	}
}
.p-contact__tab:first-child {
	border-left: none;
}
.p-contact__tab.is-active {
	color: #FFF;
	background:$blue;
}
.p-contact__form {
	margin-top: 40px;
	input[type=text], button, select, textarea {
		margin: 0;
		padding: 0;
		background: none;
		border: none;
		border-radius: 0;
		outline: none;
	}
	input[type=radio] {
		display: none;
	}
	select::-ms-expand {
    display: none;
	}
}
.p-contact-form {
	border-top: 1px solid $bdGray;
}
.p-contact-form__row {
	width: 100%;
	padding: 22px 0;
	border-bottom: 1px solid $bdGray;
	display: flex;
}
.p-contact-form__header {
	width: 270px;
	height: 45px;
	padding: 0 40px 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.p-contact-form__title {
	color: #000;
	@include fontsize(14);
	font-weight: bold;
}
.p-contact-form__required {
	color: #e00000;
	@include fontsize(11);
	font-weight: bold;
	display: inline-block;
	width: 35px;
	height: 20px;
	line-height: 18px;
	text-align: center;
	border: 1px solid #e00000;
	background: #fdf2f2;
}
.p-contact-form__culumn {
	color: #000;
	@include fontsize(14);
	padding-right: 20px;
	flex: 1;
	display: flex;
	align-items: center;
}
.p-contact-form .mw_wp_form .error {
	color: #e00000;
	@include fontsize(11);
	font-weight: bold;
	line-height: 1.3;
	display: inline-block;
}
.p-contact-form .mw_wp_form .p-contact-form__culumn .error {
	padding-left: 20px;
}
.mw_wp_form_preview .p-contact-form__culumn {
	line-height: 1.5;
}
input[type=text].p-contact-form__input-text,
select.p-contact-form__select-subject,
textarea.p-contact-form__textarea {
	color: #000;
	@include fontsize(14);
	background: $bgGray;
	border: 1px solid $bdGray;
}
input[type=text].p-contact-form__input-text:focus,
select.p-contact-form__select-subject:focus,
textarea.p-contact-form__textarea:focus {
	background: #FFF;
}
input[type=text].p-contact-form__input-text {
	width: calc(100% - 170px);
	height: 45px;
	line-height: 43px;
	padding: 0 15px;
}
.p-contact-form__select-wrapper {
	display: inline-block;
	// margin-right: 10px;
	position: relative;
	&::before,&::after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		right: 10px;
	}
	&::before {
		border-width: 0 4px 6px 4px;
		border-color: transparent transparent #666 transparent;
		top: 15px;
	}
	&::after {
		border-width: 6px 4px 0 4px;
		border-color: #666 transparent transparent transparent;
		bottom: 15px;
	}
}
.mw_wp_form_preview .p-contact-form__select-wrapper {
	&::before,&::after {
		content: none;
	}
}
select.p-contact-form__select-subject {
	width:  360px;
	height: 45px;
	line-height: 43px;
	padding: 0 15px;
	border-radius: 3px;
}
.p-contact-form__radio {
	color: #000;
	@include fontsize(14);
	height: 45px;
	line-height: 45px;
	position: relative;
	display: inline-block;
	margin-right: 20px;
	padding-left: 30px;
	cursor: pointer;
	&::before,&::after {
		content: "";
		display: block;
		border-radius: 50%;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
	}
	&::before {
    left: 6px;
    width: 8px;
    height: 8px;
    background-color: #FFF;
    z-index: 2;
	}
	&::after {
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $bdGray;
    background: #fff;
		z-index: 1;
		transition: .15s;
	}
}
.p-contact__form input[type=radio]:checked + .p-contact-form__radio::after {
	background: $blue;
	border: $blue;
}
textarea.p-contact-form__textarea {
	width: calc(100% - 170px);
	height: 250px;
	line-height: 1.8;
	padding: 10px 15px;
}
.p-contact-form__agreement {
	color: $ftGray;
	@include fontsize(14);
	width: 100%;
	height: 90px;
	margin-top: 80px;
	border: 1px solid $bdGray;
	background: $bgBlue;
	display: flex;
	align-items: center;
	justify-content: center;
}
.mwform-checkbox-field-text {
	display: none;
}
.p-contact-form__agreement-error {
	margin-top: 10px;
	text-align: center;
}
.mw_wp_form.p-contact-form__agreement-error .error {
	padding-left: 0;
}
.p-contact-form__checkbox-text {
	color: $ftGray;
	@include fontsize(14);
	display: inline-block;
	cursor: pointer;
	a {
		@include hoverColor($blue,$hoverBlue);
	}
}
.mw_wp_form_preview .p-contact-form__checkbox-text {
	display: none;
}
.p-contact-form__submit {
	margin-top: 80px;
	text-align: center;
}
input.p-contact-form__submit-button {
	color: #FFF;
	@include fontsize(16);
	font-weight: bold;
	width: 340px;
	height: 50px;
	line-height: 50px;
	display: inline-block;
	position: relative;
	border: none;
	background-color: $blue;
	background-image: url(../images/contact/submit_arrow.png);
	background-repeat: no-repeat;
	background-position: 20px 50%;
	background-size: 16px;
	cursor: pointer;
	@include hoverOpacity;
}
.p-contact-complete__message {
	width: 100%;
	padding-top: 60px;
}
.p-contact-complete__message-large {
	color: $blue;
	@include fontsize(28);
	font-weight: bold;
}
.p-contact-complete__message-small {
	color: $ftGray;
	@include fontsize(14);
	line-height: 1.8;
	margin-top: 40px;
}
@include mq-sp {
	.p-contact {
		padding-top: 0;
	}
	.p-contact__tab {
		font-size: 11px;
		height: auto;
    line-height: 16px;
		padding: 12px 6px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.p-contact__form {
		margin: 20px 20px 0;
	}
	.p-contact-form__row {
		padding: 20px 0;
		display: block;
	}
	.p-contact-form__header {
		width: 100%;
		height: auto;
		padding: 0;
		justify-content: flex-start;
	}
	.p-contact-form__title {
		font-size: 13px;
	}
	.p-contact-form__required {
		font-size: 10px;
		width: 30px;
		height: 15px;
		line-height: 13px;
		margin-left: 10px;
	}
	.p-contact-form__culumn {
		font-size: 13px;
		padding: 0;
		margin-top: 15px;
		width: 100%;
		display: block;
	}
	.mw_wp_form .error {
		font-size: 10px;
		padding-left: 0;
		margin-top: 10px;
	}
	input[type=text].p-contact-form__input-text,
	select.p-contact-form__select-subject,
	textarea.p-contact-form__textarea {
		font-size: 13px;
	}
	input[type=text].p-contact-form__input-text {
		width: 100%;
		height: 40px;
		line-height: 38px;
		padding: 0 10px;
	}
	.p-contact-form__select-wrapper {
		width: 100%;
		&::before {
			border-width: 0 3px 5px 3px;
			top: 13px;
		}
		&::after {
			border-width: 5px 3px 0 3px;
			bottom: 13px;
		}
	}
	select.p-contact-form__select-subject {
		font-size: 13px;
		letter-spacing: -0.025em;
    font-feature-settings: "palt";
		width: 100%;
		height: 40px;
		line-height: 38px;
		padding: 0 10px;
	}
	.p-contact-form__radio {
		font-size: 13px;
		height: 40px;
		line-height: 40px;
		margin-right: 10px;
		padding-left: 18px;
		&::before {
			left: 4px;
			width: 6px;
			height: 6px;
		}
		&::after {
			width: 14px;
			height: 14px;
		}
	}
	textarea.p-contact-form__textarea {
		width: 100%;
		padding: 10px;
	}
	.p-contact-form__agreement {
		height: 50px;
		margin-top: 40px;
	}
	.p-contact-form__checkbox-text {
		font-size: 13px;
	}
	.p-contact-form__submit {
		margin-top: 40px;
	}
	input.p-contact-form__submit-button {
		font-size: 14px;
		width: 240px;
		background-position: 10px 50%;
		background-size: 14px;
	}
	.p-contact-complete__message {
		padding-top: 30px;
	}
	.p-contact-complete__message-large {
		font-size: 16px;
	}
	.p-contact-complete__message-small {
		font-size: 13px;
		margin-top: 20px;
	}
}

/**
* その他のページ
**/
.p-iso__section {
	width: 100%;
	@include mq-sp {
		padding: 0 20px;
	}
}
.p-iso__mark {
	width: 164px;
	height: auto;
}
.p-privacy__section {
	width: 100%;
	@include mq-sp {
		padding: 0 20px;
	}
}

.p-privacy-acceptance {
	width: 100%;
	margin-top: 100px;
	padding: 50px 40px;
	background-color: $bgGray;
	border-radius: 5px;

	@include mq-sp {
		margin-top: 50px;
		padding: 30px 20px;
		border-radius: 0;
	}
}
.p-privacy-acceptance__buttons {
	display: flex;
	justify-content: center;
	gap: 30px;

	@include mq-sp {
		flex-direction: column-reverse;
		gap: 10px;
	}
}
.p-privacy-acceptance__note {
	color: $blue;
	@include fontsize(16);
	font-weight: bold;
	text-align: center;
	line-height: 1.5;
	margin-top: 20px;

	@include mq-sp {
		@include fontsize(14);
		text-align: left;
		text-indent: -1.3em;
		padding-left: 1.3em;
	}
}

.p-privacy-accepted-headline {
	@include fontsize(30);
	text-align: center;
	line-height: 1.5;
	margin-bottom: 1.75em;

	@include mq-sp {
		@include fontsize(16);
		text-align: left;
	}
}