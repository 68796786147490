@charset "UTF-8";
/* ----------------------------------------------------------------
front-page
TOPページのみのスタイル
-----------------------------------------------------------------*/
/*---------- common ----------*/
.p-home-section {
	padding: 80px 0;
	@include mq-sp {
		padding: 40px 0;
	}
}
.p-home-section__inner {
	@include contPad;
	max-width: 1680px;
	@include mq-sp {
		width: 100%;
		padding: 0 20px;
	}
}
.p-home-section__headline {
	color: #000;
	font-family: $fontUbuntu;
	@include fontsize(36);
	font-weight: 400;
	letter-spacing: -0.05em;
	text-indent: -0.05em;
	text-transform: capitalize;
	text-align: center;
	@include mq-sp {
		font-size: 25px;
	}
}
.p-home-column {
	width: 100%;
	margin-bottom: -3.125%;
	display: flex;
	flex-wrap: wrap;
}
.p-home-column__box {
	width: 31.25%;
	margin-left: 3.125%;
	margin-bottom: 40px;
}
.p-home-column__box:first-child,
.p-home-column__box:nth-child(3n+1) {
	margin-left: 0;
}
@include mq-sp {
	.p-home-column {
		margin-bottom: 0;
		display: block;
	}
	.p-home-column__box {
		width: 100%;
		margin-left: 0;
		margin-bottom: 0;
	}
}

/*---------- hero ----------*/
.p-home-hero {
	width: 100%;
	position: relative;
	@include mq-sp {
		display: none;
	}
}
.p-home-hero__inner {
	width: 100%;
}
.p-home-hero__slide {
	margin: 0;
	padding: 0;
}
.p-home-hero__slide-image {
	width: 100%;
	height: auto;
}
.p-home-hero__copy {
	width: 654px;
	height: 149px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.p-home-hero__copy-inner {
	width: 100%;
	height: 100%;
	position: relative;
}
.p-home-hero__copy-main {
	width: 100%;
	height: auto;
	position: relative;
}
.p-home-hero__copy-sub {
	position: absolute;
	bottom: -36px;
	right: -62px;
}

.p-home-hero-50th {
	width: calc(100vw - 160px);
	margin: 0 auto;
	max-width: 1680px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 10px;
}
.p-home-hero-50th__banner {
	line-height: 1;
	display: block;
	width: 220px;
	margin-left: auto;
	background-color: #fff;
	box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.16);
}
.p-home-hero-50th__logo {
	text-align: center;
	padding: 15px 0 13px;
	background-color: #EBFBFF;
	transition: opacity .2s;
	> img {
		width: 124px;
		height: auto;
	}
	.p-home-hero-50th__banner:hover & {
		opacity: 0.7;
	}
}
.p-home-hero-50th__text {
	color: #fff;
	font-size: 13px;
	font-weight: bold;
	width: 100%;
	height: 30px;
	background-color: #00C6FF;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity .2s;
	> .icon-window {
		font-size: 10px;
		margin-left: 4px;
	}
	.p-home-hero-50th__banner:hover & {
		opacity: 0.7;
	}
}

//for SP
.p-home-hero--sp {
	display: none;
	@include mq-sp {
		display: block;
		width: 100%;
		height: calc(100vh - 60px);
		position: relative;
	}
}
.p-home-hero__inner--sp {
	width: 100%;
	height: 100%;
	pointer-events: none;
}
.p-home-hero__slide--sp {
	width: 100%;
	height: calc(100vh - 60px);
	overflow: hidden;
}
.p-home-hero__slide-image--sp {
	width: 100%;
	height: 100%;
	object-fit: cover;
	// object-position: 50% 40%;
}
.p-home-hero__copy--sp {
	width: 87.2vw;
	max-width: 654px;
	height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.p-home-hero__copy-inner--sp {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.p-home-hero__copy-main--sp {
	position: relative;
	left: 4px;
	bottom: 35px;
}
.p-home-hero__button {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 2px solid #FFF;
	position: absolute;
	bottom: 40px;
	left: 0;
	right: 0;
	margin: auto;
	i {
		color: #FFF;
		font-size: 16px;
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}

/*---------- important ----------*/
.p-home-important {
	padding-top: 40px;
	@include mq-sp {
		padding: 20px 20px 0;
	}
}
.p-home-important__inner {
	@include contPad(160,80);
	max-width: 1200px;
	padding: 20px 40px;
	border-radius: 10px;
	@include mq-sp {
		width: 100%;
		padding: 15px;
		border-radius: 5px;
	}
}
.p-home-important__inner--gray {
	border: 4px solid #dcdcdc;
	@include mq-sp {
		border-width: 2px;
	}
}
.p-home-important__inner--red {
	border: 4px solid #ff3c3c;
	background: #ffebeb;
	@include mq-sp {
		border-width: 2px;
	}
}
.p-home-important__inner--blue {
	border: 4px solid #34c8ff;
	background: #f3f9fd;
	@include mq-sp {
		border-width: 2px;
	}
}
.p-home-important__headline {
	color: #333;
	@include fontsize(18);
	font-weight: bold;
	line-height: 1.3;
	margin-bottom: 10px;
	@include mq-sp {
		font-size: 14px;
	}
}
.p-home-important__body {
	color: #333;
	@include fontsize(18);
	line-height: 1.4;
	a {
		color: #333;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	@include mq-sp {
		font-size: 14px;
		a {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

/*---------- solution ----------*/
.p-home-solution {
	background: #FFF;
}
.p-home-solution__bodycopy {
	color: $ftGray;
	@include fontsize(18);
	line-height: 32px;
	margin-top: 40px;
	text-align: center;
}
.p-home-solution__contents {
	padding-top: 50px;
}
.p-home-solution__content {
	border: 1px solid #dbf0fd;
	box-shadow:0px 0px 10px 0px rgba(#000,0.1);
	background: $bgBlue;
	border-radius: 10px;
}
.p-home-solution__content-inner {
	display: block;
	@include hoverOpacity;
}
.p-home-solution__content-header {
	color: #FFF;
	@include fontsize(18);
	font-weight: normal;
	background: $blue;
	text-align: center;
	padding: 30px 0;
	border-radius: 10px 10px 0 0;
}
.p-home-solution__content-body {
	color: $ftGray;
	@include fontsize(14);
	line-height: 24px;
	padding: 30px 40px;
}
@include mq-sp {
	.p-home-solution__bodycopy {
		font-size: 14px;
		line-height: 1.65;
		margin-top: 30px;
		text-align: left;
	}
	.p-home-solution__contents {
		padding-top: 30px;
	}
	.p-home-solution__content {
		box-shadow:0px 0px 5px 0px rgba(#000,0.1);
		border-radius: 5px;
		margin-top: 10px;
	}
	.p-home-solution__content:first-child {
		margin-top: 0;
	}
	.p-home-solution__content-header {
		font-size: 14px;
		text-align: left;
		padding: 20px;
		border-radius: 5px;
	}
	.p-home-solution__content-body {
		display: none;
	}
}

/*---------- information ----------*/
.p-home-information {
	background: $bgGray;
}
.p-home-information__contents {
	margin-top: 60px;
	border-top: 1px solid $bdGray;
}
.p-home-information__post {
	padding: 22px 0;
	border-bottom: 1px solid $bdGray;
}
.p-home-information__post-inner {
	display: flex;
}
.p-home-information__post-meta {
	font-size: 0;
	width: 200px;
	padding-right: 20px;
}
.p-home-information__post-date {
	color: #000;
	font-family: $fontUbuntu;
	@include fontsize(14);
	font-weight: bold;
	line-height: 28px;
	display: inline-block;
	width: 6.2em;
	vertical-align: top;
}
.p-home-information__post-category {
	color: $ftBlue;
	@include fontsize(11);
	background: #FFF;
	border: 2px solid $ftBlue;
	display: inline-block;
	text-align: center;
	width: 84px;
	height: 28px;
	line-height: 24px;
	overflow: hidden;
	border-radius: 14px;
}
.p-home-information__post-title {
	color: $ftBlue;
	@include fontsize(14);
	font-weight: normal;
	line-height: 28px;
	flex: 1;
	transition: .2s;
}
.p-home-information__post-inner:hover > .p-home-information__post-title {
	color: $blue;
}

@include mq-sp {
	.p-home-information {
		padding-bottom: 10px;
	}
	.p-home-information__contents {
		margin-top: 10px;
		border-top: none;
	}
	.p-home-information__post {
		padding: 30px 0;
	}
	.p-home-information__post:last-child {
		border-bottom: none;
	}
	.p-home-information__post-inner {
		display: block;
	}
	.p-home-information__post-meta {
		width: 100%;
		padding-right: 0;
		display: flex;
		align-items: center;
	}
	.p-home-information__post-date {
		font-size: 13px;
		font-weight: bold;
		line-height: 1;
		width: auto;
		margin-left: 10px;
		order: 2;
	}
	.p-home-information__post-category {
		font-size: 10px;
		width: 80px;
		height: 24px;
		line-height: 20px;
		border-radius: 12px;
		order: 1;
	}
	.p-home-information__post-title {
		font-size: 14px;
		line-height: 1.8;
		margin-top: 10px;
		transition: none;
	}
	.p-home-information__post-inner:hover > .p-home-information__post-title {
		color: $blue;
	}
}

/*---------- rectuit ----------*/
.p-home-recruit {
	height: 450px;
	background-image: url(../images/home/recruit_bg.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
}
.p-home-recruit__inner {
	@include contPad;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
.p-home-recruit__headline {
	color: #FFF;
}
.p-home-recruit__catchcopy {
	color: #FFF;
	@include fontsize(18);
	font-weight: bold;
	text-align: center;
	margin-top: 40px;
}
.p-home-recruit__bodycopy {
	color: #FFF;
	@include fontsize(14);
	line-height: 26px;
	margin-top: 10px;
	text-align: center;
}
.p-home-recruit__button {
	margin-top: 30px;
	text-align: center;
}
.p-home-recruit__button-inner {
	color: #FFF;
	@include fontsize(14);
	display: inline-block;
	border: 2px solid #FFF;
	border-radius: 10px;
	padding: 20px 70px;
	text-indent: -0.5em;
	@include hoverOpacity;
}
@include mq-sp {
	.p-home-recruit {
		height: auto;
		background-image: url(../images/home/recruit_bg-sp.jpg);
		padding: 40px 0;
	}
	.p-home-recruit__inner {
		width: 100%;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		padding: 0 20px;
	}
	.p-home-recruit__catchcopy {
		font-size: 15px;
		line-height: 1.3;
		text-align: left;
		margin-top: 25px;
	}
	.p-home-recruit__bodycopy {
		font-size: 13px;
		line-height: 1.8;
		text-align: left;
	}
	.p-home-recruit__button {
		margin-top: 20px;
	}
	.p-home-recruit__button-inner {
		font-size: 13px;
		display: block;
		border-radius: 5px;
		padding: 0;
		height: 40px;
		line-height: 36px;
	}
}

/*---------- products & service ----------*/
.p-home-service {
	background: $bgGray;
}
.p-home-service__contents {
	padding-top: 60px;
}
.p-home-service__content {
	background: #FFF;
	border-radius: 10px;
}
.p-home-service__content-inner {
	display: block;
	@include hoverOpacity;
	padding: 20px;
}
.p-home-service__content-header {
	width: 100%;
	padding-top: 40%;
	border: 2px solid $bdGray;
	border-radius: 10px;
	position: relative;
}
.p-home-service__content-logo {
	width: calc(100% - 20px);
	max-width: 280px;
	height: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.p-home-service__content-body {
	padding: 0 10px 10px;
	margin-top: 30px;
}
.p-home-service__content-headline {
	color: $blue;
	@include fontsize(16);
	font-weight: normal;
}
.p-home-service__content-headline > i {
	position: relative;
	top: 1px;
}
.p-home-service__content-description {
	color: $ftGray;
	@include fontsize(14);
	line-height: 26px;
	margin-top: 20px;
}
@include mq-sp {
	.p-home-service__contents {
		padding-top: 40px;
	}
	.p-home-service__content {
		border-radius: 5px;
		margin-top: 20px;
	}
	.p-home-service__content-inner {
		padding: 20px;
	}
	.p-home-service__content:first-child {
		margin-top: 0;
	}
	.p-home-service__content-logo {
		max-width: 187px;
	}
	.p-home-service__content-body {
		padding: 0;
		margin-top: 15px;
	}
	.p-home-service__content-headline {
		font-size: 15px;
	}
	.p-home-service__content-headline > i {
		top: 0;
	}
	.p-home-service__content-description {
		font-size: 13px;
		line-height: 1.8;
	}
}
