@charset "UTF-8";
/* ----------------------------------------------------------------
	50周年記念ページのスタイル
-----------------------------------------------------------------*/

.p-50th {
  font-family: Helvetica, Arial, Verdana, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;;
  min-width: 1060px;
  font-size: 16px;
  line-height: 2.125;
  @include mq-sp {
    min-width: 0;
    font-size: 14px;
    line-height: 30px;
  }
}

// header
// --------------------------------------------------------------- //

.p-50th-header {
  position: relative;
  z-index: 10;
}
.p-50th-header-logo {
  position: absolute;
  top: 30px;
  left: 40px;
  @include mq-sp {
    left: 20px;
  }
}
.p-50th-header-logo__inner {
  display: block;
  width: 87px;
  height: 55px;
  > img {
    width: 100%;
    height: 100%;
  }
  @include mq-sp {
    width: 57px;
    height: 36px;
  }
}


// main
// --------------------------------------------------------------- //

.p-50th-main {
  position: relative;
  z-index: 1;
}

/*---------- hero ----------*/

.p-50th-hero {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 8px;
    background: linear-gradient(to left, #00e001 0%, #46f9ff 49.75%, #00c6ff 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
  }
  @include mq-sp {
    &::after {
      height: 5px;
    }
  }
}
.p-50th-hero-slider {
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}
.p-50th-hero-slider__image {
  height: 100%;
  > img {
    width: auto;
    max-width: none;
    height: 100%;
  }
}

.p-50th-hero-inner {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
.p-50th-hero-heading {
  width: 500px;
  margin: 0 auto;
  @include mq-sp {
    width: 100%;
  }
}
.p-50th-hero-heading__text {
  color: #fff;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  display: block;
  text-align: center;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  @include mq-sp {
    font-size: 26px;
  }
}
.p-50th-hero-heading__image {
  width: 100%;
  margin-top: 20px;
  display: block;
  > img {
    width: 100%;
    height: auto;
  }
  @include mq-sp {
    width: 265px;
    margin: 10px auto 0;
  }
}
.p-50th-hero-body {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 31px;
  text-align: center;
  white-space: nowrap;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  margin-top: 30px;
  @include mq-sp {
    font-size: 14px;
    line-height: 24px;
    margin-top: 15px;
  }
}


/*---------- message ----------*/

.p-50th-message {
  padding: 100px 0;
  @include mq-sp {
    padding: 40px 0;
  }
}
.p-50th-message-inner {
  width: calc(100% - 80px);
  max-width: 1060px;
  margin: 0 auto;
  @include mq-sp {
    width: calc(100% - 40px);
    max-width: none;
  }
}
.p-50th-message-heading {
  color: #00C6FF;
  font-size: 32px;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 50px;
  @include mq-sp {
    font-size: 22px;
    margin-bottom: 30px;
    text-align: center;
  }
}
.p-50th-message-body {
  display: flex;
  @include mq-sp {
    flex-direction: column-reverse;
    align-items: center;
  }
}
.p-50th-message-text {
  flex: 1;
  margin-right: 60px;
  p + p {
    margin-top: 2.125em;
  }
  @include mq-sp {
    max-width: 100%;
    flex-basis: auto;
    margin-top: 30px;
    margin-right: 0;
    p + p {
      margin-top: 30px;
    }
  }
}
.p-50th-message-president {
  width: 300px;
  img {
    width: 100%;
    height: auto;
  }
  @include mq-sp {
    text-align: center;
    img {
      width: 180px;
    }
  }
}
.p-50th-message-president__foot {
  line-height: 30px;
  text-align: right;
  margin-top: 20px;
  &.p-50th-message-president__foot--sp {
    display: none;
  }
  @include mq-sp {
    line-height: 24px;
    width: 100%;
    &.p-50th-message-president__foot--sp {
      display: block;
    }
    &.p-50th-message-president__foot--pc {
      display: none;
    }
  }
}
.p-50th-message-president__sub {}
.p-50th-message-president__main {
  font-size: 20px;
  @include mq-sp {
    font-size: 18px;
    margin-top: 0 !important;
  }
}


/*---------- resolution ----------*/

.p-50th-resolution {}
.p-50th-resolution-inner {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  @include mq-sp {
    max-width: none;
  }
}
.p-50th-resolution-text {
  width: 33.33333%;
  background: linear-gradient(to right, #04bce6 0%, #28d1d6 48.68%, #08e134 100%);
  position: absolute;
  top: 0;
  left: 0;
  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  @include mq-sp {
    width: 100%;
    position: static;
    &::before {
      content: none;
    }
  }
}
.p-50th-resolution-text-inner {
  width: 100%;
  height: 100%;
  // padding: 0 50px;
  padding-left: calc(50 / 480 * 100%);
  padding-right: calc(50 / 480 * 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  @include mq-sp {
    height: auto;
    padding: 40px 20px;
    position: static;
  }
}
.p-50th-resolution-heading {
  color: #fff;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
  @include mq-sp {
    margin-bottom: 0;
  }
}
.p-50th-resolution-heading__ja {
  font-size: 36px;
  font-weight: normal;
  line-height: 1;
  @include mq-sp {
    font-size: 22px;
  }
}
.p-50th-resolution-heading__en {
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  line-height: 1;
  @include mq-sp {
    font-size: 14px;
    margin-top: 10px;
  }
}
.p-50th-resolution-body {
  color: #fff;
  width: 100%;
  @include mq-sp {
    display: none;
  }
}
.p-50th-resolution-background {
  width: 100%;
  padding-top: calc(216 / 144 * 100%);
  background-image: url(../images/50th/img_resolution_pc.jpg);
  background-position: center top;
  background-size: cover;
  @include mq-sp {
    padding-top: calc(2250 / 375 * 100%);
    background-image: url(../images/50th/img_resolution_sp.jpg);
  }
}

// footer
// --------------------------------------------------------------- //

.p-50th-footer {
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mq-sp {
    padding: 20px;
  }
}
.p-50th-footer-logo {}
.p-50th-footer-logo__inner {
  display: flex;
  align-items: center;
  > img {
    width: 87px;
    height: 55px;
  }
  @include mq-sp {
    > img {
      width: 57px;
      height: 36px;
    }
  }
}
.p-50th-footer-logo__text {
  color: #00C6FF;
  font-size: 14px;
  margin-left: 20px;
  .icon-window {
    font-size: 12px;
    margin-left: 6px;
  }
  @include mq-sp {
    font-size: 12px;
    line-height: 16px;
    margin-left: 15px;
    .icon-window {
      font-size: 10px;
      margin-left: 4px;
    }
  }
}
.p-50th-copyright {
  margin-top: 20px;
  @include mq-sp {
    margin-top: 10px;
    text-align: center;
  }
}
.p-50th-copyright__inner {
  font-size: 12px;
  line-height: 1;
  @include mq-sp {
    font-size: 10px;
    letter-spacing: -.05em;
  }
}