@charset "UTF-8";
/*----------------------------------------------------------------
リストのコンポーネント
-----------------------------------------------------------------*/

.c-text-indent {
  text-indent: 1em;
  .c-text-indent--2 {
    text-indent: 2em;
  }
  .c-text-indent--3 {
    text-indent: 3em;
  }
}