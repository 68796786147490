@charset "UTF-8";
/* ----------------------------------------------------------------
mixin
-----------------------------------------------------------------*/
// calc
@mixin calc($property, $expression) {
	#{$property}: -moz-calc(#{$expression});
	#{$property}: -o-calc(#{$expression});
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: calc(#{$expression});
}

// box-shadow
@mixin boxShadow($blur,$alpha) {
	box-shadow: 0 2px $blur 0 rgba(0, 0, 0, $alpha);
}


// opacity
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacityIE: $opacity * 100;
	filter: alpha(opacity=$opacityIE);
}


// border radius
@mixin borderRadius($size) {
	border-radius: $size;
}


// transition
@mixin trans($duration: .2s,$delay: 0s) {
	transition: all $duration ease-in-out $delay;
}

// transform
@mixin transform($transform) {
	transform: $transform;
}
// scale
@mixin scale($scale) {
	@include transform(scale($scale));
}
// rotate
@mixin rotate($rotate) {
	@include transform(rotate($rotate));
}


// placeholder
@mixin placeholdercolor($selection, $default, $focus) {
	&::selection,
	&::-moz-selection {
		color: $selection;
	}
	//&:placeholder-shown,
	&::-webkit-input-placeholder,
	&:-moz-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder {
		color: $default;
		opacity: 1;
	}
	&:focus::-webkit-input-placeholder,
	&:focus:-moz-placeholder,
	&:focus:-ms-input-placeholder {
		color: $focus;
	}
}

@mixin selectNone {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

//formreset
@mixin formreset {
	background-image: none;
	background-color: none;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

//btn
@mixin btnAccent($clr , $darken) {
	background: $clr;
	color: $clrLink;
	&:hover {
		background: darken($clr , $darken);
		// color: $clrWht;
	}
}

// placeholder
@mixin placeholder($clr) {
	&::-webkit-input-placeholder {
		color:$clr;
	}
	&::-moz-placeholder {
		color:$clr;
	}
	&:-ms-input-placeholder {
		color:$clr;
	}
}

//crearfix
@mixin clearfix {
	&::after {
		content:" ";
    display:block;
    clear:both;
	}
}

//fontsize
@mixin fontsize($size: 24, $base: 16) {
	font-size: $size + px;
	font-size: ($size / $base) * 1rem;
}

/**
* RWD
**/
//PC / タブレット（767px以上）
@mixin mq-pc {
	@media screen and (min-width: 768px) {
		@content;
	}
}
//laptop（1279px以下）
@mixin mq-lt {
	@media screen and (max-width: 1279px) {
		@content;
	}
}
//smart phone（767px以下）
@mixin mq-sp {
	@media not screen and (min-width: 768px) {
		@content;
	}
}

//content width
@mixin contPad($paddingPc: 80,$paddingLt: 40) {
	@if $paddingPc == 0 {
		width: 100%;
	} @else {
		$paddingPc: $paddingPc * 2 + px;
		width: calc(100vw - #{$paddingPc});
	}
	margin: 0 auto;
	@include mq-lt {
		@if $paddingLt == 0 {
			// min-width: 1080px;
			width: 100%;
		} @else {
			$paddingLt: $paddingLt * 2 + px;
			// min-width: calc(1080px - #{$paddingLt});
			width: calc(100vw - #{$paddingLt});
		}
	}
	@include mq-sp {
		min-width: initial;
	}
}

@mixin contWidth($maxWidth: 1040px) {
	width: 100%;
	max-width: $maxWidth;
	margin-left: auto;
	margin-right: auto;
}

/**
* hoverアニメーション
**/
@mixin hoverOpacity($val: 0.6) {
	transition: opacity .2s;
	opacity: 1;
	&:hover {
		opacity: $val;
	}
	@include mq-sp {
		transition: none;
		&:hover {
			opacity: 1;
		}
	}
}
@mixin hoverColor($val1: #000,$val2: #03a8f4) {
	transition: color .2s;
	color: $val1;
	&:hover {
		color: $val2;
	}
	@include mq-sp {
		transition: none;
		&:hover {
			color: $val1;
		}
	}
}

/**
* グラデーション
**/
@mixin grad($opacity: 1) {
	background: linear-gradient(to right, rgba(#00c9ff,$opacity) 0%,rgba(#00ffff,$opacity) 50%,rgba(#00e000,$opacity) 100%);
}
@mixin gradBg($opacity: 1) {
	background: linear-gradient(to right, rgba(#c4edff, $opacity) 0%, rgba(#f0ffeb, $opacity) 100%);
}
@mixin gradTxtBg($opacity: 1) {
	background: linear-gradient(to right, rgba(#53d1fc, $opacity) 0%, rgba(#59e652, $opacity) 100%);
}
