@charset "UTF-8";
/* ----------------------------------------------------------------
footer内のコンテンツのスタイル
-----------------------------------------------------------------*/
.p-footer {
  @include contPad;
  max-width: 1440px;
  @include mq-sp {
    width: 100%;
    padding: 0 20px;
  }
}

/**
* footer-top
**/
.p-footer__top {
  width: 100%;
  padding: 60px 0 30px;
  display: flex;
  justify-content: space-between;
}
.p-footer-logo {
  display: inline-block;
  a {
    display: flex;
    align-items: center;
  }
}
.p-footer-logo__symbol {
  width: 87px;
  img {
    width: 100%;
    height: auto;
  }
}
.p-footer-logo__text {
  color: #000;
  @include fontsize(14);
  font-weight: bold;
  padding-left: 20px;
}
.p-footer-contact {}
.p-footer-contact__button {
  color: #fff;
  @include fontsize(16);
  font-weight: bold;
  line-height: 1;
  background: $blue;
  display: block;
  text-align: center;
  width: 280px;
  border-radius: 5px;
  padding: 21px 0;
  position: relative;
  @include hoverOpacity;
  & > .icon-link_right {
    font-size: 17px;
    height: 17px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 26px;
    margin: auto;
  }
}
@include mq-sp {
  .p-footer__top {
    padding: 25px 0;
    flex-direction: column;
    align-items: center;
  }
  .p-footer-logo {
    display: inline-block;
    a {
      display: flex;
      align-items: center;
    }
  }
  .p-footer-logo__symbol {
    width: 55px;
  }
  .p-footer-logo__text {
    font-size: 11px;
    padding-left: 10px;
    letter-spacing: -0.05em;
  }
  .p-footer-contact {
    margin-top: 20px;
    width: 100%;
  }
  .p-footer-contact__button {
    @include fontsize(14);
    width: 100%;
    padding: 20px 0;
    & > .icon-link_right {
      font-size: 15px;
      height: 15px;
      left: 26px;
    }
  }
}

/**
* footer-middle
**/
.p-footer__middle {
  width: 100%;
  padding: 20px 0 70px;
  border-top: 1px solid $bdGray;
}
.p-footer-navi {
  display: flex;
}
.p-footer-navi__culumn {
  flex: 1;
  padding-right: 20px;
}
.p-footer-navi__header {
  @include fontsize(14);
  line-height: 1.2;
  display: block;
  @include hoverColor($ftBlue,$blue);
  margin-top: 20px;
  i {
    display: none;
  }
}
.p-footer-navi__item {
  margin-top: 16px;
  padding: 0;
}
.p-footer-navi__label {
  @include fontsize(12);
  line-height: 18px;
  display: inline-block;
  padding-left: 15px;
  text-indent: -15px;
  @include hoverColor($ftBlue,$blue);
  i {
    color: #ccc;
    font-size: 10px;
    line-height: 18px;
    margin-right: 5px;
  }
}
@include mq-sp {
  .p-footer__middle {
    width: 100%;
    padding: 0 0 40px;
    border-top:none;
  }
  .p-footer-navi {
    display: block;
    padding-bottom: 0;
    border-left: 1px solid $bdGray;
    border-right: 1px solid $bdGray;
    border-bottom: 1px solid $bdGray;
  }
  .p-footer-navi__culumn {
    width: 100%;
    padding-right: 0;
  }
  .p-footer-navi__header {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 40px;
    height: 40px;
    display: block;
    margin-top: 0;
    padding: 0 10px;
    text-indent: 0;
    border-top: 1px solid $bdGray;
    i {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      vertical-align: top;
    }
  }
  .p-footer-navi__menu {
    display: none;
  }
}

/**
* footer-bottom
**/
.p-footer__bottom {
  width: 100%;
  padding: 30px 0;
  border-top: 1px solid $bdGray;
}
.p-footer-contents {
  display: flex;
  justify-content: space-between;
  // text-align: right;
}
.p-footer-contents__links {
  font-size: 0;
}
.p-footer-contents__item {
  @include fontsize(12);
  vertical-align: top;
  display: inline-block;
  margin-right: 20px;
  @include hoverColor($ftBlue, $blue);
}
.p-footer-contents__copyright {
  color: #000;
  font-family: $fontUbuntu;
  @include fontsize(12);
  display: inline-block;
}
@include mq-sp {
  .p-footer__bottom {
    padding: 20px 0 25px;
  }
  .p-footer-contents {
    display: block;
    text-align: center;
  }
  .p-footer-contents__links {
  }
  a.p-footer-contents__item {
    font-size: 12px;
    display: block;
    margin-right: 0;
    margin-top: 10px;
  }
  .p-footer-contents__copyright {
    display: block;
    text-align: center;
    margin-top: 25px;
    font-size: 10px;
    letter-spacing: -0.05em;
  }
}