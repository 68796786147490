@charset "UTF-8";
/* ----------------------------------------------------------------
.sidebarのスタイル
-----------------------------------------------------------------*/
.p-sidebar {
  width: 260px;
  background: #FFF;
  position: relative;
  &.pos-fixed {
    position: fixed;
    top: 40px;
  }
  &.pos-bottom {
    position: absolute;
    bottom: 0;
  }
}
.p-sidebar-headline {
  @include fontsize(22);
  font-weight: normal;
  @include hoverColor(#000,$blue);
  i {
    color: $blue;
  }
}
.p-sidebar-navi {
  width: 100%;
  margin-top: 20px;
}
.p-sidebar-navi__menu {
  border: 1px solid $bdGray;
}
.p-sidebar-navi__item {
  display: block;
  width: 100%;
  border-top: 1px solid $bdGray;
}
.p-sidebar-navi__item:first-child {
  border-top: none;
}
.p-sidebar-navi__label {
  @include fontsize(14);
  line-height: 20px;
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 50px 10px 20px;
  position: relative;
  @include hoverColor($blue,$hoverBlue);
  i {
    font-size: 17px;
    display: inline-block;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
  }
}
.p-sidebar-navi__submenu {
  margin: 0 20px 20px;
  padding: 20px;
  background: $bgBlue;
}
.p-sidebar-navi__submenu-item {
  width: 100%;
}
a.p-sidebar-navi__submenu-label {
  color: $blue;
  @include fontsize(13);
  line-height: 2;
  text-decoration: underline;
}
a.p-sidebar-navi__submenu-label:hover {
  text-decoration: none;
}

@include mq-sp {
  .p-sidebar {
    width: 100%;
    padding: 0 20px;
    position: relative;
  }
  .p-sidebar-headline {
    font-size: 13px;
    font-weight: bold;
    display: block;
    line-height: 50px;
    height: 50px;
    text-align: center;
    border: 1px solid $bdGray;
    position: relative;
    i {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      margin: auto;
    }
  }
  .p-sidebar-navi {
    display: none;
  }
}
.p-sidebar.style-service .p-sidebar-headline {
  @include hoverColor(#000,$hoverBlue);
}
.p-sidebar.style-service .p-sidebar-headline i {
  color: $hoverBlue;
}
.p-sidebar.style-service .p-sidebar-navi__label {
  @include hoverColor($hoverBlue,$blue);
}