@charset "UTF-8";
/* ----------------------------------------------------------------
utility
-----------------------------------------------------------------*/

/* display
______________________________________________*/
.u-blk {
	display: block;
}

.u-blk--sp {
	@media #{$querySp} {
		display: block;
	}
}

.u-blk--pc {
	@media #{$queryPc} {
		display: block;
	}
}

/* hidden
______________________________________________*/
.u-hdn {
	display: none;
}

.u-hdn--sp {
	@media #{$querySp} {
		display: none;
	}
}

.u-hdn--pc {
	// @media #{$queryPc} {
	// 	display: none;
	// }
	@media screen and (min-width: 768px) {
		display: none;
	}
}

/* color
______________________________________________*/
.u-clr--wht {
	color: $clrWht !important;
}
.u-clr--blck {
	color: $clrBlck !important;
}
.u-clr--gry {
	color: $clrGry !important;
}
.u-clr--base {
	color: $clrBase!important;
}
.u-clr--main {
	color: $clrMain!important;
}
.u-clr--accent {
	color: $clrAccent!important;
}
.u-clr--primary {
	color: $clrPrimary!important;
}
.u-clr--success {
	color: $clrSuccess!important;
}
.u-clr--info {
	color: $clrInfo!important;
}
.u-clr--danger {
	color: $clrDanger!important;
}
.u-clr--warning {
	color: $clrWarning!important;
}
.u-clr--pjc01 {
	color: $clrProject01!important;
}
.u-clr--pjc02 {
	color: $clrProject02!important;
}
.u-clr--pjc03 {
	color: $clrProject03!important;
}
.u-clr--pjc04 {
	color: $clrProject04!important;
}
.u-clr--pjc05 {
	color: $clrProject05!important;
}

/* background
______________________________________________*/
.u-bg-clr--blck {
	background: $clrBlck!important;
}
.u-bg-clr--wht {
	background: $clrWht!important;
}
.u-bg-clr--gry {
	background: $clrGry!important;
}
.u-bg-clr--base {
	background: $clrBase!important;
}
.u-bg-clr--main {
	background: $clrMain!important;
}
.u-bg-clr--accent {
	background: $clrAccent!important;
}
.u-bg-clr--primary {
	background: $clrPrimary!important;
}
.u-bg-clr--success {
	background: $clrSuccess!important;
}
.u-bg-clr--info {
	background: $clrInfo!important;
}
.u-bg-clr--danger {
	background: $clrDanger!important;
}
.u-bg-clr--warning {
	background: $clrWarning!important;
}
.u-bg-clr--pjc01 {
	background: $clrProject01!important;
}
.u-bg-clr--pjc02 {
	background: $clrProject02!important;
}
.u-bg-clr--pjc03 {
	background: $clrProject03!important;
}
.u-bg-clr--pjc04 {
	background: $clrProject04!important;
}
.u-bg-clr--pjc05 {
	background: $clrProject05!important;
}

/* float
______________________________________________*/
.u-flt--r {
	float: right;
}
.u-flt--l {
	float: left;
}


/* clearfix
______________________________________________*/
.u-cf:after {
	content: "";
	clear: both;
	display: block;
}



/* font Weight
______________________________________________*/
.u-fw--nml {
	font-weight: normal!important;
}
.u-fw--bold {
	font-weight: bold!important;
}


/* align
______________________________________________*/
.u-ta--l { text-align: left !important; }
.u-ta--c { text-align: center !important; }
.u-ta--r { text-align: right !important; }


.u-va--t { vertical-align: top !important; }
.u-va--m { vertical-align: middle !important; }
.u-va--b { vertical-align: bottom !important; }

/* margin
______________________________________________*/
$marginStride: 100;

@while $marginStride > -1 {
	.u-mt-#{$marginStride} {
		margin-top: #{$marginStride}px !important;
	}
	.u-mb-#{$marginStride} {
		margin-bottom: #{$marginStride}px !important;
	}
	.u-mr-#{$marginStride} {
		margin-right: #{$marginStride}px !important;
	}
	.u-ml-#{$marginStride} {
		margin-left: #{$marginStride}px !important;
	}
	$marginStride: $marginStride - 5;
}

/* width
______________________________________________*/
$widthStride: 100;

@while $widthStride > -1 {
	.u-w-#{$widthStride} {
		width: $widthStride + "%" !important;
	}
	$widthStride: $widthStride - 5;
}

/* Padding
______________________________________________*/
$paddingStride: 100;
@while $paddingStride > -1 {
	.u-pt-#{$paddingStride} {
		padding-top: #{$paddingStride}px !important;
	}
	.u-pb-#{$paddingStride} {
		padding-bottom: #{$paddingStride}px !important;
	}
	.u-pr-#{$paddingStride} {
		padding-right: #{$paddingStride}px !important;
	}
	.u-pl-#{$paddingStride} {
		padding-left: #{$paddingStride}px !important;
	}
	.u-p-#{$paddingStride} {
		padding: #{$paddingStride}px !important;
	}
	$paddingStride: $paddingStride - 5;
}

//TAB
@while $paddingStride > -1 {
	.u-pt-#{$paddingStride}--tab {
		@media #{$queryTab} {
			padding-top: #{$paddingStride}px !important;
		}
	}
	.u-pb-#{$paddingStride}--tab {
		@media #{$queryTab} {
			padding-bottom: #{$paddingStride}px !important;
		}
	}
	.u-pr-#{$paddingStride}--tab {
		@media #{$queryTab} {
			padding-right: #{$paddingStride}px !important;
		}
	}
	.u-pl-#{$paddingStride}--tab {
		@media #{$queryTab} {
			padding-left: #{$paddingStride}px !important;
		}
	}
	.u-p-#{$paddingStride}--tab {
		@media #{$queryTab} {
			padding: #{$paddingStride}px !important;
		}
	}
	$paddingStride: $paddingStride - 5;
}

//SP
@while $paddingStride > -1 {
	.u-pt-#{$paddingStride}--sp {
		@media #{$querySp} {
			padding-top: #{$paddingStride}px !important;
		}
	}
	.u-pb-#{$paddingStride}--sp {
		@media #{$querySp} {
			padding-bottom: #{$paddingStride}px !important;
		}
	}
	.u-pr-#{$paddingStride}--sp {
		@media #{$querySp} {
			padding-right: #{$paddingStride}px !important;
		}
	}
	.u-pl-#{$paddingStride}--sp {
		@media #{$querySp} {
			padding-left: #{$paddingStride}px !important;
		}
	}
	.u-p-#{$paddingStride}--sp {
		@media #{$querySp} {
			padding: #{$paddingStride}px !important;
		}
	}
	$paddingStride: $paddingStride - 5;
}
