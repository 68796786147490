@charset "UTF-8";
/* ----------------------------------------------------------------
footerのスタイル
-----------------------------------------------------------------*/
.l-footer {
  width: 100%;
  background: #FFF;
  position: relative;
  border-top: 1px solid $bdGray;
}
.l-footer.border-none {
  border-top: none;
}