@charset "UTF-8";
/* ----------------------------------------------------------------
generalのスタイル
-----------------------------------------------------------------*/
.l-wrapper {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  position: relative;
}
